.callout {
  position: relative;
  padding: 0 .5rem;
  margin: 1rem 0;
  border: 0 solid #a4b7c1;
  border-left-width: .25rem;
  border-radius: .25rem;

  &-danger {
    border-left-color: $danger;
  }

  &-info {
    border-left-color: $info;
  }

  &-warning {
    border-left-color: $warning;
  }

  &-primary-dark {
    border-left-color: $primary;
  }

  &-primary {
    border-left-color: $brand-primary !important;
  }

  &-dark {
    border-left-color: $black;
  }

  &-success {
    border-left-color: $brand-success;
  }
}