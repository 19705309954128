.reports {
  &-header {
    .card-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        width: 100%;

        @include media-breakpoint-up(lg){
          width: 40%;
        }

        @include media-breakpoint-up(xl){
          width: 25%;
        }

        &:last-of-type {
          text-align: center;
          margin-top: 10px;
          border-top: 1px solid #e0e0e0;
          padding-top: 10px;

          @include media-breakpoint-up(lg){
            text-align: right;
            margin-top: 0;
            border-top: none;
            padding-top: 0;
          }
        }
      }
    }
  }

  &-agents {
    .card-body {
      padding: 10px;
    }

    .tabs {
      flex-wrap: wrap;

       > .col-auto {
         width: 100% !important;
         text-align: center;

         @include media-breakpoint-up(lg){
           width: 20% !important;
           text-align: center;
         }

         .nav {
           outline: none;
         }
       }

      .tab-content {
        margin-top: -1px;
        background: #fff;
        border: none;
        width: 100%;

        @include media-breakpoint-up(lg){
          width: auto;
          border-left: 1px solid #e0e0e0;
        }

        .tab-pane {
          padding: 0;
          border-top: none;
        }
      }
    }
  }

  &-games {
    border-top: none;
  }

  .general-list {
    .row {
      div {
        text-align: center;

        @include media-breakpoint-up(lg){
          text-align: left;
        };

        &:first-of-type {
          width: 100%;
          padding: 0 10px;

          @include media-breakpoint-up(lg){
            width: 80px;
          }
        }
      }
    }
  }

  &-games-tabs {
    margin-bottom: 1.5rem;

     .tab-content {
      margin-top: -1px;
      background: #fff;
      border: none;

       .tab-pane {
        padding: 0;
        border-top: 1px solid #20a8d8;
      }
    }
    .card-header {
      .nav {
        &-tabs {
          margin-top: unset;

          &.card-header-tabs {
            outline: none;
          }
        }

        &-link {
          border: 1px solid transparent !important;
          border-bottom: none;

          &.active,
          &:hover,
          &:focus {
            background-color: #20a8d8 !important;
            border-color: #20a8d8 !important;
            color: #FFF !important;
            outline: none;
          }
        }
      }
    }
  }
}

