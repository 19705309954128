.bg {
  &-lighter {
    background-color: $body-bg;
    color: $black;
  }
  &-light-gray {
    background-color: #f6f6f6;
    color: $black;
  }
  &-primary {
    background-color: $brand-primary !important;
    color: $white;
  }
  &-primary-20 {
    background-color: rgba(48, 151, 209, 0.2) !important;
    color: black;
  }
  &-primary-dark {
    background-color: $primary;
    color: $white;
  }
  &-info {
    background-color: $info;
    color: $white;
  }
  &-purple {
    border-color: #c61197;
    background-color: #c61197;
    color: #fff;
  }
}