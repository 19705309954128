.campaign-added-leads-section {
	.card-body {
		padding: 10px;
	}

	.filter-links-holder {
		margin-bottom: 10px;
		padding: 0;
	}

	hr {
		margin-bottom: 10px;
		margin-top: 0;
	}

	&-list {
		&-item {
			border-left: 1px solid #e0e0e0;
			border-right: 1px solid #e0e0e0;
			border-top: 1px solid #e0e0e0;

			&:last-of-type {
				border-bottom: 1px solid #e0e0e0;
			}
		}

		&-item-header {
			background-color: #f6f6f6;
			border-bottom: 1px solid #e0e0e0;
			display: block;
			padding: 10px;
			width: 100%;

			@include media-breakpoint-up(xl) {
				display: flex;
			}

			.text-separator {
				display: none;
				margin-right: 5px;

				@include media-breakpoint-up(sm) {
					display: inline-block;
				}
			}

			> span {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				font-weight: 600;
				justify-content: flex-start;
				width: 100%;

				a {
					width: 100%;

					@include media-breakpoint-up(sm) {
						width: auto;
					}
				}
			}

			> div {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;

				@include media-breakpoint-up(xl) {
					flex-wrap: nowrap;
					width: auto;
				}

				> a {
					margin-right: 0;
					padding-right: 10px;
					width: 100%;

					@include media-breakpoint-up(sm) {
						width: auto;
					}

					@include media-breakpoint-up(xl) {
						border-right: 1px solid #e0e0e0;
					}

					&:last-of-type {
						margin-right: 0;
					}
				}

				> span {
					align-items: center;
					display: flex;
					justify-content: center;
					margin-right: 5px;

					@include media-breakpoint-up(sm) {
						width: 90px;
					}

					> a {
						margin-right: 5px;
					}

					&:last-of-type {
						margin-right: 0;
					}

					.remove-list-item-button {
						margin: 0;

						a {
							margin-right: 5px;

							&:last-of-type {
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		&-item-body {
			align-items: flex-start;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 10px;

			> div {
				width: 100%;

				@include media-breakpoint-up(sm) {
					width: 50%;
				}

				@include media-breakpoint-up(xl) {
					width: 25%;
				}
			}
		}

		.no-items-found-message {
			font-weight: bold;
			padding: 15px 0;
		}
	}
}

.campaign-add-leads-section {
	&-header {
		align-items: center;
		background-color: #f6f6f6;
		border: 1px solid #e0e0e0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 10px;
		width: 100%;
	}

	&-list {
		&-item {
			border: 1px solid #e0e0e0;
			margin-bottom: 10px;

			&:last-of-type {
				border-bottom: 1px solid #e0e0e0;
			}
		}

		&-item-header {
			align-items: center;
			background-color: #f6f6f6;
			border-bottom: 1px solid #e0e0e0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 10px;
			width: 100%;

			label {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 0;

				input[type='checkbox'] {
					margin-bottom: 0;
					margin-right: 5px;
				}
			}

			a {
				margin-right: 5px;
			}

			.button-add,
			.button-remove {
				align-items: center;
				border: none;
				display: flex;
				height: 25px;
				justify-content: center;
				outline: none;
				transition: all .4s;
				width: 25px;
			}

			.button-add {
				@extend .bg-primary;
				@extend .text-white;

				&:hover {
					@extend .bg-primary;
					opacity: .7;
				}
			}

			.button-remove {
				@extend .bg-danger;
				@extend .text-white;

				&:hover {
					@extend .bg-danger;
					opacity: .7;
				}
			}
		}

		&-item-body {
			align-items: flex-start;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 10px;

			> div {
				width: 100%;

				@include media-breakpoint-up(sm) {
					width: 50%;
				}

				@include media-breakpoint-up(xl) {
					width: 25%;
				}
			}
		}

		&-item-footer {
			align-items: center;
			background-color: #f6f6f6;
			border-top: 1px solid #e0e0e0;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.campaigns {
				display: flex;
				align-items: center;
				margin-right: 10px;
				flex-wrap: wrap;

                > span {
                    @extend .bg-danger;
                    @extend .text-white;
                    font-size: 10px;
                    font-weight: bold;
                    padding: 5px;
                    text-transform: uppercase;
                }

                > div {
                    align-items: center;
                    display: flex;
                }
            }

			.batch {
				display: flex;
				align-items: center;
				margin-right: 5px;

                > span {
                    @extend .bg-secondary;
                    font-size: 10px;
                    font-weight: bold;
                    padding: 5px;
                    text-transform: uppercase;
					margin-right: 5px;
                }
            }
		}
	}
}

.campaign-add-leads-filter-form {
	border: 1px solid #e0e0e0;
	margin-bottom: 1rem;

	&-header {
		@extend .bg-primary;
		@extend .text-white;
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 0.615rem;
	}

	&-body {
		display: block;
		padding: 0.615rem;
	}

	&-body-holder {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		> div {
			display: flex;
			margin-bottom: 0.615rem;
			width: 100%;

			@include media-breakpoint-up(lg) {
				width: calc(50% - 5px);
			}

			&:first-of-type {
				align-items: center;
				background-color: #f6f6f6;
				justify-content: center;
			}

			&:last-of-type {
				align-items: center;
				display: flex;
				margin-right: 0;
				width: 100%;
				margin-bottom: 0;

				div {
					margin-right: 10px;
					flex: 1;

					&:last-of-type {
						margin-right: 0;
					}
				}
			}
		}
	}

	&-footer {
		align-items: center;
		background-color: #f6f6f6;
		color: #000;
		display: flex;
		justify-content: flex-start;
		padding: 0.615rem;

		button {
			margin-right: .615rem;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.already-in-campaign-checkbox {
		align-items: center;
		display: flex;
	}
}