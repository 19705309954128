.statistics {
  &-holder{
    display: flex;

    &-card {
      width: 50%;

      &:last-of-type {
        padding-left: 15px;
        border-left: 1px solid #e0e0e0;
      }
    }

    &-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &-data {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      flex: 1;

      h5 {
        margin: 0;
      }
    }

    &-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }
}

// ---------------------
.statistic {
  &-card {
    border: 0;

    .card-body {
      display: flex;
      padding: 10px 0px;

      @media (min-width: 1200px) {
        padding: 15px 0px;
      }
    }
  }
  &-icon {
    align-items: center;
    border-right: 1px solid #ffffff;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    justify-content: center;
    max-width: 68px;
    width: 100%;

    @media (min-width: 1200px) {
      max-width: 78px;
    }
  }
  &-icon-label {
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 5px;

    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
  &-inner {
    padding: 0px 10px;
    width: 100%;
  }
  &-crm {
    align-items: center;
    color: #ffffff;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-web {
    align-items: center;
    color: #ffffff;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
  }
}

// ---------------------
.dashboard {
  // Dashboard Players
  &-players {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .25);
    padding: 25px;
  }
  &-players-heading {
    border-bottom: 1px solid #eee;
    padding-bottom: .5rem;
  }
  &-players-title {
    color:  #2f353b;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 15px;
    position: relative;
    text-transform: uppercase;
  }
  // Dashboard Sales
  &-sales {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .25);
    padding: 25px;

    &:hover {
      .dashboard-sales-title {
        &:after {
          transition: all .2s;
          width: 100%;
        }
      }
    }
  }
  &-sales-heading {
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
  }
  &-sales-title {
    color:  #2f353b;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 15px;
    position: relative;
    text-transform: uppercase;
  }
  // Dashboard Calls
  &-calls {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .25);
    padding: 25px;

    &:hover {
      .dashboard-calls-title {
        &:after {
          transition: all .2s;
          width: 100%;
        }
      }
    }
  }
  &-calls-heading {
    border-bottom: 1px solid #eee;
    padding-bottom: .5rem;
  }
  &-calls-title {
    color:  #2f353b;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 15px;
    position: relative;
    text-transform: uppercase;
  }
  // Dashboard Deposits
  &-deposits {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .25);
    height: 100%;
    padding: 25px;

    &:hover {
      .dashboard-deposits-title {
        &:after {
          transition: all .2s;
          width: 100%;
        }
      }
    }
  }
  &-deposits-heading {
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
  }
  &-deposits-title {
    color:  #2f353b;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 15px;
    position: relative;
    text-transform: uppercase;
  }
  // Dashboard Bonus
  &-bonus {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .25);
    height: 100%;
    padding: 25px;

    .card-body {
      padding: 17.5px 0px;
    }

    &:hover {
      .dashboard-bonus-title {
        &:after {
          transition: all .2s;
          width: 100%;
        }
      }
    }
  }
  &-bonus-heading {
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
  }
  &-bonus-title {
    color:  #2f353b;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 15px;
    position: relative;
    text-transform: uppercase;
  }
  // Dashboard Disposition
  &-disposition {
    background-color: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .25);
    padding: 25px;

    &:hover {
      .dashboard-disposition-title {
        &:after {
          transition: all .2s;
          width: 100%;
        }
      }
    }

    .card-body {
      display: flex;
      flex-wrap: wrap;
    }

    a {
		font-size: 12px;
		display: flex;
		align-items: center;
    }
  }
  &-disposition-heading {
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
  }
  &-disposition-title {
    color:  #2f353b;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 15px;
    position: relative;
    text-transform: uppercase;
  }
}

.widget {
  &-brand-title {
    font-size: 16px;
  }
  &-brand-icon {
    font-size: 1.5rem;
    padding: 5px 0 0;

    @media (min-width: 576px) {
      font-size: 2.5rem;
      padding: 25px 0 0;
    }
  }
}