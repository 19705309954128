.atbl-input-holder {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      padding-top: 0;
      padding-bottom: calc(0.375rem + 1px);
      margin-bottom: 0;
      font-size: inherit;
      line-height: 1.6;
      font-weight: bold;
    }

    .atbl-input-loader {
      margin: 0 0 5px 0 !important;
    }

    .fa {
      &-check {
        color: green;
      }
      &-times {
        color: red;
      }
    }
  }

  &-body {

  }
}