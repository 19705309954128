.tabs-atbl {
    border : none;

    .tab-content {
        border  : none;
        padding : 0 !important;

        .tab-pane {
            border-top : 1px solid #20a8d8;

            .card-body {
                padding : 0 15px;
            }
        }
    }

    .nav {
        &-tabs {
            background-color : #f6f6f6;
            border-bottom    : none;
            border-left      : 1px solid #e0e0e0;
            border-right     : 1px solid #e0e0e0;
            border-top       : 1px solid #e0e0e0;
            margin-top       : unset;
            padding          : 10px 10px 0 10px !important;

            &.card-header-tabs {
                outline : none;
            }
        }

        &-link {
            border-bottom : none;
            border-left   : 1px solid transparent !important;
            border-radius : 0;
            border-right  : 1px solid transparent !important;
            border-top    : 1px solid transparent !important;

            &.active,
            &:hover,
            &:focus {
                background-color : #20a8d8 !important;
                border-color     : #20a8d8 !important;
                color            : #FFF !important;
                outline          : none;
            }
        }
    }

    &-statistics {
        &-header {
            align-items      : center;
            background-color : #f6f6f6;
            border           : 1px solid #d8dbe0;
            color            : #000;
            display          : flex;
            justify-content  : space-between;
            padding          : 0.75rem;
        }

        &-body {
            > .card .card-body {
                padding : 0 15px;
            }
        }
    }
}

.atbl-tabs {
    &.vertical {
        display: flex;

        .atbl-tabs {
            &__header {
                display: flex;
                flex-wrap: wrap;
                width: 25%;
                flex-direction: column;
            }
        }
        .atbl-tab {
            flex: 1;
        }
    }

    &__header {
        background-color : #f6f6f6;
        border-bottom    : 2px solid #20a8d8;
        border-left      : 1px solid #e0e0e0;
        border-right     : 1px solid #e0e0e0;
        border-top       : 1px solid #e0e0e0;
        display          : flex;
        flex-wrap        : wrap;
        list-style       : none;
        margin-bottom    : 0;
        margin-top       : unset;
        padding          : 10px 10px 0 10px !important;

        li {
            cursor  : pointer;
            display : block;
        }
    }

    &__tab {
        border-bottom : none;
        border-radius : 0;
        border-right  : 1px solid transparent !important;
        border-top    : 1px solid transparent !important;
        display       : block;
        font-size     : 14px;
        padding       : 0.5rem 1rem;

        &__selected {
            background-color : #20a8d8 !important;
            color            : #FFF !important;
            outline          : none;
        }

        &.disabled {
            pointer-events: none;
            opacity: .7;
        }
    }
}