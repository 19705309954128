.sort-by {
    align-items : center;
    display     : flex;

    > span {
        color          : #6f7488;
        display        : block;
        font-size      : .6875rem;
        font-weight    : 600;
        line-height    : 1;
        margin-right   : 10px;
        text-transform : uppercase;
    }

    select {
        background-color : #fff;
        border-color     : #c7c7d4;
        box-shadow       : none;
        color            : #343446;
        float            : left;
        font-weight      : 400;
        height           : 25px;
        line-height      : 34px;
        overflow         : hidden;
        padding          : 0 0 0 0.4rem;
        position         : relative;
        text-align       : left;
        text-overflow    : ellipsis;
        text-transform   : initial;
        transform        : translate3d(0, 0, 0);
        user-select      : inherit;
        white-space      : nowrap;
    }

    button {
        align-items      : center;
        background-color : #fff;
        border           : 1px solid #c7c7d4;
        box-shadow       : none;
        color            : #4b4b60;
        display          : flex;
        height           : 25px;
        justify-content  : center;
        outline          : none;
        text-transform   : none;
        width            : 25px;

        span {
            display : block;
            width   : 100%;

            > span {
                background-color : #000;
                height           : 2px;
                margin-bottom    : 1px;

                &:nth-child(2) {
                    width : 80%;
                }

                &:nth-child(3) {
                    width : 60%;
                }

                &:nth-child(4) {
                    width : 40%;
                }
            }

            &.rotated {
                transform : scaleY(-1);
            }
        }
    }
}