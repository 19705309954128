.payment {
    &-close {
        background-color: transparent;
        color: white;
        text-decoration: none;
        outline: none !important;
        border: none;
        transition: all .4s;

        &:hover,
        &:focus {
            background-color: rgba(255,255,255, .1);
        }
    }

    &-types {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &-button {
        min-width: 100px;
        min-height: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-icon {
        display: block;

        img {
            width: 25px;
            margin: auto;
        }
    }

    @import "payment-oppwa-client";
}

.payment-gateways {
    display : flex;
    min-height: 40px;

    &--disabled {
        pointer-events : none;

        .payment-gateway-button {
            opacity : 0.75;
        }

        .loader {
            position : absolute;
            top      : 0;
            bottom   : 0;
            left     : 0;
            right    : 0;
            margin   : auto !important;
            width    : 2em;
            height   : 2em;
        }
    }

    &--container {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }

    .payment-gateway-button {
        background  : #3b5998;
        color       : #ffffff;
        padding     : 8px;
        cursor      : pointer;
        position    : relative;
        text-align: center;
        min-width: 6rem;

        &--disabled {
            opacity : 0.8;
            pointer-events: none;
        }

        &--wallet {
            background: #e55353;
        }

        &--oppwa {
            background: #f9b115;
        }

        &--jeton {
            background: #ff6300;
        }

        &--apco-aponetouch {
            background: #ff0000;
        }

        &--apco-payretailers {
            background: #3245b9;
        }

        &--skrill {
            background: #4F2455;
        }

        &--bambora {
            background: #8247B5;
        }

        span {
            display        : inline-block;
            border-top     : 0.3em solid;
            border-right   : 0.3em solid transparent;
            border-bottom  : 0;
            border-left    : 0.3em solid transparent;
            vertical-align : middle;
        }

        &__dropdown-content {
            position   : absolute;
            top        : 100%;
            left       : 0;

            display    : none;
            min-width  : 10rem;
            margin-top : 1px;
            border     : 1px solid #dcdcdc;

            background : white;

            a {
                display         : block;
                text-decoration : none;
                padding         : 8px;
            }
        }
    }

    .modal__payment-cashier {
        .modal-body {
            padding : 0;
        }
    }

    .payment-gateway-button--toggled {
        .payment-gateway-button__dropdown-content {
            display : block;
            z-index: 999;
        }
    }
}