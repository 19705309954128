.configuration {
  margin-bottom : 20px;

  h5 {
    padding          : 10px 10px 10px;
    border           : 1px solid #e0e0e0;
    margin           : 0;
    text-transform   : uppercase;
    font-size        : 14px;
    font-weight      : bold;
    color            : #878787;
    cursor           : pointer;
    display          : flex;
    justify-content  : space-between;
    align-items      : center;
    background-color : #f6f6f6;

    &.success {
      background-color : #dbf2e3;
    }
  }

  input {
    width         : 50px;
    height        : 50px;
    border-radius : 50%;
    outline       : none;
    border        : 1px solid #e0e0e0;
    text-align    : center;
    font-size     : 20px;
    transition    : all .4s;

    &:focus {
      border-color : #2d2d2d;
    }

    &.input-has-error {
      border : 1px solid #F00;
    }

    &.configuration-text {
      width         : 98%;
      height        : unset;
      border-radius : unset;

      &-input {
        width         : auto;
        border-radius : unset;
        height        : unset;
      }
    }
  }

  &-holder {
    display         : flex;
    justify-content : center;
    padding         : 15px 0 0;

    &-multiple {
      display         : flex;
      width           : 100%;
      flex-wrap       : wrap;
      justify-content : center;
    }

    &-single {
      display        : flex;
      width          : 50%;
      flex-direction : column;
      margin         : 5px 0;
      align-items    : center;
      text-align     : center;

      &.full {
        width : 100%;
      }

      input {
        margin-right : 5px;
      }
    }
  }

  &-input-holder {
    margin-right : 5px;
  }

  &-form {
    background-color : #f6f6f6;
    border           : 1px solid #e0e0e0;
    margin-right     : 5px;
    padding          : 10px;
    position         : relative;

    a {
      @extend .bg-primary;
      align-items     : center;
      border          : 1px solid #e0e0e0;
      border-radius   : 50%;
      color           : #fff;
      display         : flex;
      height          : 20px;
      justify-content : center;
      position        : absolute;
      right           : -10px;
      text-decoration : none;
      top             : -10px;
      transition      : all .4s;
      width           : 20px;
      z-index         : 999;
    }

    p {
      border-bottom  : 1px solid #e0e0e0;
      font-weight    : bold;
      padding-bottom : 10px;
    }

    > div {
      input {
        font-size     : 14px;
        margin-bottom : 5px;
        width         : 100% !important;
      }

      button {
        @extend .btn;
        @extend .btn-sm;
        width : 100%;
        margin-bottom: 5px;

        &:first-of-type {
          @extend .btn-primary;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &-winning-prizes {
    display : flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    flex-wrap: wrap;

    &-item {
      width: calc(50% - 5px);
      text-align:center;
      position: relative;
      margin-right: 5px;
      margin-bottom: 5px;

      &:nth-child(even) {
        margin-right: 0;
      }

      a {
        align-items     : center;
        border          : 1px solid #e0e0e0;
        border-radius   : 50%;
        color           : #fff;
        display         : flex;
        height          : 20px;
        justify-content : center;
        position        : absolute;
        right           : 5px;
        text-decoration : none;
        top             : 5px;
        transition      : all .4s;
        width           : 20px;
        z-index         : 999;

        &.add-field {
          @extend .bg-success;
        }

        &.remove-field {
          @extend .bg-danger;
        }
      }

      &.editable {
        background-color: #f6f6f6;
        border: 1px solid #e0e0e0;
        padding: 10px;
      }
    }

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px 5px 0 5px;

      &.editable {
        justify-content: space-between;
      }

      span {
        @extend .text-secondary;
        font-weight: bold;
      }
    }
  }
}