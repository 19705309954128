// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Bootstrap
//@import "~bootstrap-sass/assets/stylesheets/bootstrap";

// Vue component
@import '../../node_modules/vue2-daterange-picker/dist/vue2-daterange-picker.css';
@import '../../node_modules/@riophae/vue-treeselect/dist/vue-treeselect.css';

// Fonts
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

// CoreUI Style
@import "~@coreui/coreui/scss/coreui";

// Variables
@import "variables";

//override
@import "override/override";

// helpers
@import "helpers/helpers";

// components
@import "components/form/forms";
@import "components/badges/ribon";
@import "components/badges/avatar";
@import "components/badges/callout";
@import "components/modals/modal";
@import "components/buttons/buttons";
@import "components/buttons/circle-increment-decrement";
@import "components/tabs/tabs";
@import "components/lists/paginations";
@import "components/lists/remove-list-item-button";
@import "components/payment/payment";
@import "components/loaders/loaders";
@import "components/heading/base-heading-separator";
@import "components/dial-keypad/dial-keypad";
@import "components/plugins/audio-player";
@import "components/form/atbl-date-range-picker";
@import "components/sort-by/sort-by";
@import "components/transactions/transaction-type-badge";
@import "components/dropdown/atbl-dropdown";
@import "components/popover/popover";
@import "components/tooltip/tooltip";

//override
@import "override/paddings";
@import "override/links";

// layout
@import "layout/layout";
@import "layout/sidebar";
@import "components/lists/lists";

// Forms
@import "components/lists/lists";
@import "components/cards/cards";
@import "components/badges/small-md-badge";
@import "components/links/sort-link";
@import "pages/games/draws-configuration";
@import "components/sidebar/sidebar-sub";

// Pages styles
@import "pages/dashboard/dashboard";
@import "pages/games/draws";
@import "pages/players/players";
@import "pages/reports/agents";
@import "pages/reports/games";
@import "pages/reports/sales";
@import "pages/status/status";
@import "pages/marketing/marketing";
@import "pages/tickets/preview";
@import "pages/players/player-funds";
@import "pages/users/roles";
@import "pages/users/free-games-settings";
@import "pages/withdrawals/withdrawals";
@import "pages/callbacks/callbacks";
@import "pages/orders/orders";
@import "pages/users/manage-account";
@import "pages/email-templates/email-templates";
@import "pages/permissions/permissions-manager";
@import "pages/roles/roles-manager";
@import "pages/games/draw-review";
@import "pages/commissions/commissions-eligible-transactions-single-day";
@import "pages/discounts/discounts";
@import "pages/payments/payment-gateways-settings";

#app {
  width: 100%;
}

.v-sort {
    cursor: pointer;

  .v-sort-btn {
    display     : inline-block;
    width       : 12px;
    height      : 12px;
    margin-left : 2px;

    &:before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f0dc";
    }
  }
  
  &.active {
    color: #3097D1 !important;
      
    &[data-sort="asc"] {
        .v-sort-btn:before {
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            content: "\f0de";
        }
    }

    &[data-sort="desc"] {
      .v-sort-btn:before {
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          content: "\f0dd";
      }
    }
  }
}

.sticky-element {
    position: sticky;
    top: 57px;
    z-index: 999;
}