.game-draw-review {
    img {
        height : 50px;
    }

    .card-header {
        align-items     : center;
        display         : flex;
        justify-content : space-between;
    }
}

.game-draw-review-details {
    img {
        height : 50px;
    }

    .card-header {
        align-items     : center;
        display         : flex;
        justify-content : space-between;
    }

    &-info {
        .card-body {
            align-items     : center;
            display         : flex;
            flex-wrap       : wrap;
            justify-content : space-between;
            padding         : 1rem;

            div {
                margin-bottom : .315rem;
                margin-right  : .615rem;

                &:last-of-type {
                    @include media-breakpoint-up(lg) {
                        margin-bottom : 0;
                    }
                }

                span {
                    display       : block;
                    font-weight   : bold;
                    margin-bottom : .315rem;
                }
            }
        }
    }
}