.text {
    &-primary {
        color : $brand-primary !important;
    }

    &-primary-dark {
        color : $primary;
    }

    &-black {
        color : $black;
    }

    &-big {
        font-size : 1rem;
    }
}