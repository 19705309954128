.atbl-date-range-picker {
    position : relative;

    &-close {
        background-color : #ffffff;
        border-radius    : 50%;
        display          : flex;
        height           : 14px;
        position         : absolute;
        right            : -5px;
        top              : -5px;
        width            : 14px;
    }

    .vue-daterange-picker {
        display : flex;
        width   : 100%;

        .reportrange-text {
            align-items : center;
            display     : flex;
        }
    }

    .calendars-container {
        flex-wrap : wrap;
    }
}