.circle {
	background: #aaaaaa4f;
	border-radius: 100%;
	border: 1px solid #aaaaaa;
	box-shadow: inset 1px 1px 3px #ffffff;
	display: inline-block;
	height: 18px;
	margin: 4px;
	position: relative;
	vertical-align: middle;
	width: 18px;

	&:before,
	&:after {
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	&__plus:before,
	&__plus:after {
		background: green;
		box-shadow: 1px 1px 1px #ffffff9e;
	}

	&__plus:before {
		margin: 3px auto;
		width: 2px;
	}

	&__plus:after {
		box-shadow: none;
		height: 2px;
		margin: auto 3px;
	}

	&__minus:before {
		background: #cc0000;
		box-shadow: 0 1px 1px #ffffff9e;
		height: 2px;
		margin: auto 3px;
	}
}