.roles-manager {
    .permissions-header {
        align-items     : center;
        border-bottom   : 1px solid #e0e0e0;
        display         : flex;
        justify-content : space-between;
        padding         : .5rem 0;
    }

    .permissions-operations {
        align-items     : center;
        display         : flex;
        justify-content : space-between;
        margin-top      : .5rem;

        .form-group {
            flex : none;

            input {
                max-width : 150px;
            }
        }
    }

    .permissions-holder {
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;
        margin-bottom   : 5px;

        &-item {
            background-color : #e0e0e0;
            margin-bottom    : 5px;
            width            : 100%;

            &-header {
                align-items     : center;
                display         : flex;
                justify-content : space-between;
            }

            a {
                align-items     : center;
                border-bottom   : 1px solid #e0e0e0;
                border-top      : 1px solid #e0e0e0;
                color           : #000;
                display         : flex;
                font-size       : 1.2rem;
                padding         : 5px 10px 5px 10px;
                text-decoration : none;
                text-transform  : uppercase;
            }

            .atbl-checkbox {
                margin-right : 10px;
            }
        }

        &-item-list {
            align-items      : center;
            background-color : #f6f6f6;
            display          : flex;
            flex-wrap        : wrap;
            padding          : 10px 10px 0 10px;
            width            : 100%;

            > div {
                margin-bottom : 10px;
                width         : 50%;
            }
        }
    }
}
