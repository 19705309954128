.border-color-light {
  border-color: #e0e0e0 !important;
}
.border-color-primary {
  border-color: $brand-primary !important;
}
.border-color-white {
  border-color: #fff !important;
}

.border {
  &-lg {
    &-none {
      @include media-breakpoint-up(lg){
        border-bottom: none !important;
      }
    }
  }
}