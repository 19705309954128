.discounts {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;

	> div {
		margin-right: 0;
		width: 100%;

		&:last-of-type {
			margin-right: 0;
		}

		@include media-breakpoint-up(lg) {
			margin-right: 1rem;
			width: calc(50% - .5rem);
		}
	}

	&-list {
		.card-header {
			padding: .75rem;
		}

		.card-body {
			padding: 0;
		}

		.general-list {
			> div {
				align-items: center;
				display: flex;
				justify-content: space-between;
				padding: .75rem;
			}
		}
	}

	&-editor {
		.card-header {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}

		.card-body {
			padding: 1rem;
		}

		.card-footer {
			align-items: center;
			display: flex;
			justify-content: flex-end;

			button {
				@extend .btn;
				@extend .btn-sm;
				@extend .btn-danger;
			}
		}
	}

	&-form-holder {
		border-top: 1px solid #e0e0e0;
		position: relative;
		margin-bottom: 1rem;

		&:last-of-type {
			margin-bottom: 0;
		}

		&-header {
			align-items: center;
			border-bottom: 1px solid #e0e0e0;
			display: flex;
			justify-content: space-between;
			margin-bottom: 1rem;
			padding: 1rem 0;

			h3 {
				font-size: 1.2rem;
				margin: 0;
			}

			button:not(.atbl-dropdown-header) {
				@extend .btn;
				@extend .btn-sm;
				@extend .btn-primary;
				margin-right: .5rem;
			}
		}

		&-row {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			&-header {
				align-items: center;
				background-color: #f6f6f6;
				display: flex;
				font-weight: bold;
				justify-content: space-between;

				> div {
					align-items: center;
					display: flex;
					flex-basis: 20%;
					padding: .315rem;
				}
			}

			> div {
				align-items: center;
				display: flex;
				flex-basis: 20%;
				padding: .315rem;

				&.error {
					display: block;
					flex-basis: unset;
					width: 100%;

					> div {
						@extend .text-danger;
					}
				}
			}
		}

		&-error {
			@extend .alert;
			@extend .alert-danger;
			margin-top: .75rem;
			margin-bottom: 0;
		}
	}
}