.small-mb-badge {
  display: block;
  width: 100%;
  color: #d1d1d1;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;

  &.show {
    display: block !important;
  }

  &.black {
    color: #000 !important;
  }

  @media(min-width: 992px){
    display:none;
  }

  &-extended {
    display: block;
    width: 100%;
    color: #d1d1d1;
    margin-bottom: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;

    &.black {
      color: #000 !important;
    }

    @media(min-width: 1200px){
      display:none;
    }
  }

  &.inline {
    display: inline-block;
    width: unset;
    margin-right: 10px;

    @media(min-width: 992px){
      display:none;
    }
  }
}