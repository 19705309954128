.badge {
  &-primary-dark {
    background-color: $primary;
    color: $white;
  }
  &-primary {
    background-color: $brand-primary !important;
    color: $white;
  }
  &-warning {
    color: $black;
  }
}