.manage-account {
    &-profile {
        align-items   : center;
        background    : #f6f6f6;
        border        : 1px solid #d8dbe0;
        display       : flex;
        margin-bottom : 1rem;
        padding       : 1rem;

        span {
            align-items      : center;
            background-color : #39f;
            border-radius    : 50%;
            color            : #fff;
            display          : flex;
            font-size        : 1.25rem;
            height           : 50px;
            justify-content  : center;
            margin-right     : 1rem;
            width            : 50px;
        }

        h4 {
            margin-bottom : 5px;
        }

        p {
            @extend .text-muted;
            margin : 0;
        }
    }

    &-password {
        form {
            display : block;
            padding : 15px 0;
            width   : 100%;

            @include media-breakpoint-up(sm) {
                max-width : 300px;
            }

            &.disabled {
                opacity        : .7;
                pointer-events : none;
            }
        }

        button {
            @extend .btn;
            @extend .btn-primary;
            width : 100%;

            @include media-breakpoint-up(sm) {
                width : auto;
            }
        }
    }
}