.atbl-file-upload {
    position : relative;

    input {
        width : 100%;
    }

    a {
        @extend .text-danger;
        background-color : #ffffff;
        border-radius    : 50%;
        display          : flex;
        position         : absolute;
        right            : 0;
        text-transform   : uppercase;
        top              : 5px;
    }
}