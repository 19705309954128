.ribbon {
    $colors : (
            'green': (
                    background: #79A70A,
                    background-gradient: linear-gradient(#9BC90D 0%, #79A70A 100%)
            ),
            'primary': (
                    background: $brand-primary,
                    background-gradient: linear-gradient(#35a5e8 0%, #2b8bbf 100%)
            ),
            'danger': (
                    background: #e55353,
                    background-gradient: linear-gradient(#ff6666 0%, #bb4242 100%)
            ),
            'warning': (
                    background: $brand-orange,
                    background-gradient: linear-gradient(#ff7c2b 0%, #e35300 100%)
            )
    );

    @each $name, $glyph in $colors {
        &-#{$name} {
            position   : absolute;
            right      : -5px;
            top        : -5px;
            z-index    : 1;
            overflow   : hidden;
            width      : 75px;
            height     : 75px;
            text-align : right;

            span {
                font-size         : 10px;
                font-weight       : bold;
                color             : #FFF;
                text-transform    : uppercase;
                text-align        : center;
                line-height       : 20px;
                transform         : rotate(45deg);
                -webkit-transform : rotate(45deg);
                width             : 100px;
                display           : block;
                background        : map-get($glyph, 'background');
                background        : map-get($glyph, 'background-gradient');
                box-shadow        : 0 3px 10px -5px rgba(0, 0, 0, 1);
                position          : absolute;
                top               : 19px;
                right             : -21px;

                &::before {
                    content       : "";
                    position      : absolute;
                    left          : 0;
                    top           : 100%;
                    z-index       : -1;
                    border-left   : 3px solid map-get($glyph, 'background');
                    border-right  : 3px solid transparent;
                    border-bottom : 3px solid transparent;
                    border-top    : 3px solid map-get($glyph, 'background');
                }

                &::after {
                    content       : "";
                    position      : absolute;
                    right         : 0;
                    top           : 100%;
                    z-index       : -1;
                    border-left   : 3px solid transparent;
                    border-right  : 3px solid map-get($glyph, 'background');
                    border-bottom : 3px solid transparent;
                    border-top    : 3px solid map-get($glyph, 'background');
                }
            }
        }
    }
}