.remove-list-item-button {
    display : flex;
    align-items: center;
    justify-content: center;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        text-decoration: none;
        transition: all 0.4s;
    }

    &-trash {
        @extend .text-danger;
        font-size: 14px;
    }

    &-cancel {
        @extend .bg-danger;
        @extend .text-white;
        margin-right: .313rem;
        font-size: 10px;
    }

    &-confirm {
        @extend .bg-success;
        @extend .text-white;
        font-size: 10px;
    }
}