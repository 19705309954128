.basket {
    .avatar {
        width: 50px;
        height: 50px;
    }

    .atbl-dropdown-header {
        width: auto;
    }

    .atbl-dropdown-content {
        width : 300px;

        @include media-breakpoint-up(lg) {
            width : 400px;
        }

        .card-header {
            padding: 10px;

            &-title {
                display : flex;
                align-items: center;

                i {
                    margin-right: 5px;
                }
            }

            &-operations {
                display : flex;
                align-items: center;

                button {
                    padding: 0;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    margin-right: 5px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        .card-body {
            padding: 0;
        }

        .no-items-found {
            padding: 10px 0;
        }
    }

    .items-indicator {
        margin-right : 11px;
        position     : relative;

        &:after {
            content          : "";
            width            : 1px;
            height           : 100%;
            background-color : #ffffff8c;
            position         : absolute;
            right            : -6px;
        }
    }

    &-item {
        display : flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px;
        position: relative;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:nth-child(odd) {
            background-color: #f6f6f6;
        }
    }

    &-image {
        margin-right: 10px;
    }

    &-data {
        flex: 1;
        margin-right: 10px;
    }

    &-title {
        justify-content: flex-start;
        display: flex;
        width: 100%;

        .title {
            color: #666;
        }
    }

    &-price {
        display : flex;
        justify-content : space-between;
        align-items: center;
    }

    &-items-data {
        display : flex;
        justify-content : flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    &-operations {
        width: 50px;
        position: absolute;
        top: 5px;
        right: 0;

        @include media-breakpoint-up(lg) {
            position: relative;
        }

        button {
            padding: 0;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            margin-right: 5px;

            &:last-of-type {
                margin-right: 0;
            }

            i {
                margin: 0;
            }
        }
    }
}