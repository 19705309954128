.call-history {
  &-statistic-card {
    margin-bottom: 0;
    height: 100%;
    border-top-width: 3px !important;
    border-right: 1px solid #c2cfd6 !important;
    border-bottom: 1px solid #c2cfd6 !important;
    border-left: 1px solid #c2cfd6 !important;

    &-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      > div {
        padding: 10px;

        &:last-of-type {
          font-size: 24px;
          color: #000000;
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }

    h3 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 0;
      color: #73818f;
    }

    h4 {
      font-size: 12px;
      margin-bottom: 0;
      color: #73818f;
    }

    p {
      margin: 0;
      color: #73818f;
    }

    span {
      font-size: 24px;
      color: #000000;
    }

    &.multiple {
      .call-history-statistic-card-holder {
        > div {
          padding: 10px;

          &:last-of-type {
            justify-content: space-between;

            > div {
              &:last-of-type {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  &-tabs {
    .tab-content {
      border: none;

      .tab-pane {
        border-top: 1px solid #20a8d8;
      }
    }
    .card-header {
      .nav {
        &-tabs {
          margin-top: unset;

          &.card-header-tabs {
            outline: none;
          }
        }

        &-link {
          border: 1px solid transparent !important;
          border-bottom: none;

          &.active,
          &:hover,
          &:focus {
            background-color: #20a8d8 !important;
            border-color: #20a8d8 !important;
            color: #FFF !important;
            outline: none;
          }
        }
      }
    }
  }

  &-agents-badge {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 6px 0;
    margin-right: -6px;
  }
}