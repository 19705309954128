.campaign-call {
    background-color : #FFF;
    border           : 1px solid #d8dbe0;
    margin-bottom    : 1.5rem;
    padding          : 15px;

    .profile-holder {
        align-items     : center;
        display         : flex;
        justify-content : center;
        margin-bottom   : 20px;

        &-icon {
            -webkit-box-shadow : 0 0 6px #00000026;
            align-items        : center;
            background-color   : #f6f6f6;
            border-radius      : 50%;
            border             : 1px solid #e0e0e0;
            box-shadow         : 0 0 6px #00000026;
            color              : #999;
            display            : flex;
            font-size          : 26px;
            height             : 100px;
            justify-content    : center;
            text-transform     : uppercase;
            width              : 100px;
        }
    }

    .dial-buttons {
        display : flex;
        width   : 100%;

        .mute-button {
            -ms-flex-align    : center;
            -ms-flex          : 0.1;
            -webkit-box-align : center;
            -webkit-box-flex  : 0.1;
            align-items       : center;
            border            : none;
            box-shadow        : none;
            display           : block;
            flex              : 0.1 !important;
            font-size         : 16px;
            outline           : none;
            padding           : 10px 15px;
            width             : 50px;

            &:hover,
            &:focus {
                box-shadow : none;
                outline    : none;
            }
        }

        .btn {
            box-shadow : none;
            flex       : 1;
            outline    : none;

            &:hover,
            &:focus {
                box-shadow : none;
                outline    : none;
            }
        }
    }

    .keypad-icon-holder {
        display : flex;
        width   : 100%;

        .keypad-icon {
            background-color : #000;
            border-radius    : 50%;
            height           : 2px;
            width            : 2px;
        }
    }
}