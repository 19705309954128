.general {
  &-list {
    > div {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 0;

      &:first-of-type,
      &.first-row-style {
        font-weight: bold;
        border-top: 1px solid #a4b7c1;
        border-bottom: 1px solid #a4b7c1;
        background-color: #f6f6f6;
        display: none;

        &.show-first-row {
          display: flex;
        }

        @include media-breakpoint-up(lg) {
          display: flex;
        }
      }

      &:nth-child(odd) {
        background-color: #f6f6f6;
      }

      &.last {
        background-color: #f6f6f6;
      }
      
      &:hover {
        background-color: #f6f6f6;
      }

      &.disabled {
        opacity: .3;
      }
    }
  }
}