.player-play {
    &-content {
        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-between;

        .card-header {
            background-color : #f6f6f6;
            color            : #000;
        }
    }

    &-games {
        width : 100%;

        @include media-breakpoint-up(xl) {
            width : calc(100% - 315px);
        }

        .card-body {
            padding : 0;
        }

        .atbl-tabs {
            &__header {
                background-color : white;

                li {
                    &:last-of-type {
                        a {
                            background-color : white;
                            color            : #e55353;
                            border-right: 1px solid #e55353 !important;
                            border-bottom: 0;
                            border-top: 1px solid #e55353 !important;
                            border-left: 1px solid #e55353 !important;

                            &.atbl-tabs__tab__selected {
                                background-color : #e55353 !important;
                                color            : white !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &-games-holder {
        display   : flex;
        width     : 100%;
        flex-wrap : wrap;

        .game {
            width          : 100%;
            border         : none;
            box-shadow     : 0 0 3px #0000003b;
            display        : flex;
            padding        : 10px;
            flex-direction : row;
            text-align     : center;

            @media (min-width : 1280px) {
                flex-direction : row;
            }

            @include media-breakpoint-up(sm) {
                width          : 50%;
                flex-direction : column;
                align-items    : center;
            }

            @include media-breakpoint-up(lg) {
                flex-direction : row;
            }

            @include media-breakpoint-up(xl) {
                width       : 25%;
                align-items : center;
            }

            &-thumb {
                width  : 40px !important;
                height : 40px !important;
            }

            &-play {
                display         : flex;
                flex-wrap       : wrap;
                justify-content : center;
                width           : 100%;
                text-overflow   : ellipsis;
                overflow        : hidden;
                white-space     : nowrap;

                p {
                    margin        : 0 !important;
                    width         : 100%;
                    text-align    : center;
                    text-overflow : ellipsis;
                    overflow      : hidden;
                    white-space   : nowrap;
                }

                button {
                    padding        : 2px 10px;
                    margin-top     : 5px;
                    text-transform : uppercase;
                    font-size      : 14px;
                }
            }

            &.syndicate {
                @include media-breakpoint-up(xl) {
                    width : 33.3333%;
                }
            }

            &.bundle {
                flex-wrap       : wrap;
                flex-direction  : column;
                align-items     : center;
                justify-content : space-between;

                .img-holder {
                    display         : flex;
                    width           : 100%;
                    flex-wrap       : wrap;
                    align-items     : center;
                    justify-content : center;

                    img {
                        width  : 30px !important;
                        height : 30px !important;
                    }
                }

                .game-play {
                    width : 100%;
                }

                @include media-breakpoint-up(xl) {
                    width          : 33.3333%;
                    flex-direction : column;
                    align-items    : center;
                }
            }
        }
    }

    &-checkout {
        width    : 100%;
        position : relative;

        @include media-breakpoint-up(xl) {
            width : 300px;
        }

        .checkout-summary {
            &.sticky {
                position : sticky;
            }
        }
    }

    .card-header,
    .card-body {
        padding : 10px;
    }

    &-header {
        display : block;

        &.bundle {
            p {
                font-size : 12px;
            }
        }

        button {
            position : absolute;
            top      : 0;
            right    : 0;
        }

        .game-thumb {
            width  : 50px;
            height : 50px;
        }

        &-description {
            padding : 0 15px;
        }

        > div {
            &:first-of-type {
                width           : 100%;
                display         : flex;
                justify-content : center;

                @include media-breakpoint-up(xl) {
                    width           : auto;
                    justify-content : left;
                }
            }

            &:last-of-type {
                width      : 100%;
                text-align : center;
                flex       : 1;

                p {
                    margin-bottom : 10px;
                    font-size     : 12px;

                    @include media-breakpoint-up(xl) {
                        margin-bottom : 0;
                    }
                }

                @include media-breakpoint-up(xl) {
                    width      : auto;
                    text-align : left;
                }
            }
        }
    }

    &-picker {
        &-wrapper {
            display : block;

            > div {
                &:first-of-type {
                    padding   : 0;
                    display   : flex;
                    flex-wrap : wrap;
                    width     : 100%;
                }

                &:last-of-type {
                    display         : flex;
                    justify-content : space-between;
                    flex-direction  : column;
                    align-items     : center;
                    width           : 100%;
                    margin-top      : 25px;
                }
            }

            .ticket-options {
                display         : flex;
                justify-content : center;
                flex-wrap       : wrap;
                width           : 100%;
                align-items     : flex-end;

                @include media-breakpoint-up(xl) {
                    justify-content : space-between;
                }

                &-draw {
                    display         : flex;
                    align-items     : center;
                    width           : 100%;
                    justify-content : center;
                    flex-wrap       : wrap;
                    margin-top      : 15px;

                    @include media-breakpoint-up(lg) {
                        width           : auto;
                        justify-content : flex-start;
                        align-items     : flex-start;
                        flex-wrap       : unset;
                    }

                    .form-group {
                        width  : 100%;
                        margin : 0 !important;

                        &.weekdays {
                            margin-top : 15px !important;

                            @include media-breakpoint-up(sm) {
                                margin-top : 0 !important;
                            }
                        }

                        @include media-breakpoint-up(sm) {
                            width        : auto;
                            margin-right : 0.5rem !important;
                        }

                        label {
                            padding : 0;
                        }
                    }
                }

                &-numbers {
                    width : 100%;

                    @include media-breakpoint-up(lg) {
                        width        : auto;
                        margin-right : 10px;
                    }

                    .ticket-number {
                        background-color : #f6f6f6;
                        padding          : 5px 10px;
                        border           : 1px solid #e0e0e0;
                        margin-bottom    : 15px;

                        @include media-breakpoint-up(lg) {
                            margin-bottom : 0;
                        }

                        &-group {
                            display         : flex;
                            justify-content : center;

                            @include media-breakpoint-up(lg) {
                                justify-content : flex-start;
                            }

                            span {
                                display         : flex;
                                width           : 25px;
                                height          : 25px;
                                align-items     : center;
                                justify-content : center;
                                margin-right    : 5px;
                                border          : 1px solid #000000;

                                &.last-generated {
                                    width : 35px;

                                    i {
                                        margin-left : 5px;
                                    }
                                }
                            }
                        }

                        &-picker {
                            .dropdown-menu {
                                max-width        : 35px;
                                min-width        : auto;
                                width            : 35px;
                                border           : 1px solid #000000;
                                background-color : #f6f6f6;

                                .dropdown-item {
                                    height          : 25px;
                                    padding         : 0;
                                    display         : flex;
                                    align-items     : center;
                                    justify-content : center;

                                    &:not(:last-of-type) {
                                        border-bottom : 1px solid #000000;
                                    }
                                }
                            }

                            .dropdown-toggle {
                                background   : transparent;
                                border-color : #000;
                                padding      : 0;
                                width        : 35px;
                                height       : 25px;
                            }
                        }
                    }
                }
            }
        }

        &-lines {
            margin-bottom : 5px;
            text-align    : center;
            width         : 100%;

            @include media-breakpoint-up(xl) {
                text-align : left;
            }

            &-line {
                display         : flex;
                align-items     : center;
                padding         : 10px 0;
                justify-content : center;
                flex-wrap       : wrap;
                border-bottom   : 1px solid #e0e0e0;
                position        : relative;

                @include invalid();

                &:nth-child(even) {
                    background-color : #f6f6f6;
                }

                .generated-number {
                    margin-right : 10px;
                    font-weight  : bold;
                    position     : absolute;
                    left         : 5px;
                    top          : 5px;

                    @include media-breakpoint-up(lg) {
                        position : relative;
                        left     : unset;
                        top      : unset;
                    }
                }

                button {
                    background-color : #FFF;
                    border-radius    : 5px;
                    padding          : 0 10px;
                    line-height      : 2.3em;
                    border           : 1px solid #c2cfd6;
                    text-transform   : uppercase;
                    font-size        : 14px;
                    outline          : none;
                    transition       : all .4s;

                    &.lucky-dip {
                        margin-right  : 0;
                        margin-bottom : 10px;

                        @include media-breakpoint-up(lg) {
                            margin-right  : 20px;
                            margin-bottom : 0;
                        }
                    }

                    &.remove-line {
                        padding : 0 10px;
                        color   : #F00;

                        i {
                            pointer-events : none;
                        }
                    }

                    &:hover {
                        background-color : #f6f6f6;
                    }
                }

                input[type="checkbox"] {
                    width        : 30px;
                    height       : 30px;
                    margin-right : 20px;

                    @include media-breakpoint-up(lg) {
                        width  : 20px;
                        height : 20px;
                    }
                }

                &-numbers {
                    display         : flex;
                    margin-right    : 0;
                    margin-bottom   : 10px;
                    width           : 100%;
                    justify-content : center;

                    @include media-breakpoint-up(lg) {
                        margin-right    : 20px;
                        margin-bottom   : 0;
                        width           : auto;
                        justify-content : flex-start;
                    }

                    &.disabled {
                        pointer-events : none;

                        input {
                            background-color : #f6f6f6;
                        }
                    }

                    input {
                        border-radius : 5px;
                        width         : 2rem;
                        height        : 2rem;
                        outline       : none;
                        padding       : 5px;
                        text-align    : center;

                        @include invalid();

                        &:not(:last-of-type) {
                            margin-right : 5px;
                        }

                        &:focus {
                            outline      : none;
                            box-shadow   : none;
                            border-color : unset;

                            @include invalid();
                        }
                    }
                }
            }

            .fractions-input {
                /* Firefox */
                -moz-appearance: textfield;

                /* Chrome, Safari, Edge, Opera */
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            .line-control {
                margin-bottom   : 10px;
                display         : flex;
                justify-content : center;
                align-items     : center;
                flex-wrap       : wrap;
                background      : #ffffff;
                position        : sticky;
                top             : 57px;
                z-index         : 1;
                margin-left     : -10px;
                margin-right    : -10px;
                padding         : 10px;
                border-bottom   : 1px solid #dcdcdc;

                @include media-breakpoint-up(md) {
                    justify-content : space-between;
                }

                .add-line {
                    background-color : transparent;
                    border           : none;
                    padding          : 0;
                    outline          : none;
                    transition       : all .4s;
                    margin-right     : 10px;

                    &-message {
                        color       : #F00;
                        font-size   : 12px;
                        display     : inline-block;
                        margin-left : 5px;
                    }

                    &:hover,
                    &focus {
                        color : $primary;
                    }

                    i {
                        color : #4ab971;
                    }
                }

                .remove-all-lines {
                    color : #F00;
                }

                .remove-all-confirm {
                    background-color : #2eb85c;
                    color : white;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    text-align: center;
                }

                .remove-all-cancel {
                    background-color : #e55353;
                    color : white;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    text-align: center;
                }
            }
        }
    }

    .line-message {
        font-size   : 14px;
        color       : #f86c6b;
        font-weight : bold;
        display     : block;
        width       : 100%;
        margin-top  : 15px;

        @include media-breakpoint-up(lg) {
            display    : inline-block;
            width      : auto;
            margin-top : 0;
        }
    }

    .weekdays-holder {
        width       : 100%;
        display     : flex;
        flex-wrap   : wrap;
        align-items : center;

        label {
            margin        : 0 5px 5px 0 !important;
            display       : flex;
            align-items   : center;
            border        : 1px solid #e0e0e0;
            padding       : 6px !important;
            border-radius : 3px;

            &:last-of-type {
                margin : 0 0 5px 0 !important;
            }
        }

        input[type=checkbox] {
            width        : 15px;
            height       : 15px;
            margin-right : 5px;
        }
    }

    .syndicate-group {
        > div {
            display         : flex;
            align-items     : center;
            justify-content : space-between;

            span {
                &:first-of-type {
                    font-weight : bold;
                }
            }
        }
    }

    .buy {
        width            : 100%;
        text-align       : center;
        background-color : #f6f6f6;
        padding          : 10px;
        display          : flex;
        justify-content  : flex-end;
        flex-wrap        : wrap;

        @include media-breakpoint-up(sm) {
            flex-wrap : unset;
        }

        @include media-breakpoint-up(xl) {
            text-align : right;
        }

        &-total {
            text-align   : center;
            width        : 100%;
            margin-right : 10px;

            @include media-breakpoint-up(xl) {
                text-align : right;
            }

            &-price {
                font-size   : 14px;
                line-height : 100%;
            }

            table {
                width : 100%;

                tr {
                    td {
                        &:first-of-type {
                            padding : 5px 0 0 5px;
                        }

                        &:last-of-type {
                            padding : 5px 5px 0 0;
                        }
                    }
                }
            }
        }

        &-operations {
            display         : flex;
            align-items     : center;
            flex-direction  : column;
            justify-content : center;
            width           : 100%;
            margin-top      : 10px;

            @include media-breakpoint-up(sm) {
                width      : 300px;
                margin-top : 0;
            }
        }

        &-button {
            padding        : 5px;
            box-shadow     : 0 0 6px #0000005e;
            width          : 100%;
            text-transform : uppercase;
        }
    }
}