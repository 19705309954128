.birthdate-picker {
    .vue-daterange-picker {
        width : 100%;

        &.is-invalid {
            .reportrange-text {
                border-color      : #e55353;
                background-repeat : no-repeat;
                background-size   : calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
            }
        }
    }
}