.keypad {
    background     : #e0e0e0;
    border-left    : 1px solid #2d2d2d;
    border-right   : 1px solid #2d2d2d;
    display        : flex;
    flex-direction : column;
    outline        : none;
    width          : 100%;

    &-number {
        align-items      : center;
        background-color : #f1f1f1;
        border-top       : 1px solid #2d2d2d;
        display          : flex;
        flex-wrap        : wrap;
        justify-content  : center;
        padding          : 5px;
        width            : 100%;

        .keypad-input-holder {
            flex : 1;

            .keypad-input,
            .keypad-input-digits {
                border      : none;
                font-size   : 16px;
                font-weight : bold;
                outline     : none;
                padding     : 5px 10px;
                text-align  : center;
                width       : 100%;
            }

            .keypad-input-digits {
                &::placeholder {
                    color          : #cccccc;
                    opacity        : 1;
                    text-transform : uppercase;
                }

                &:-ms-input-placeholder {
                    color          : #cccccc;
                    opacity        : 1;
                    text-transform : uppercase;
                }

                &::-ms-input-placeholder {
                    color          : #cccccc;
                    opacity        : 1;
                    text-transform : uppercase;
                }
            }
        }

        .clear-number {
            align-self : center;
            font-size  : 10px;
            padding    : 0 5px;
        }

        .backspace {
            border  : none;
            outline : none;
            padding : 5px 10px;
            width   : 45px;

            img {
                display : block;
                width   : 100%
            }
        }
    }

    &-wrapper {
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        height          : 320px;
        justify-content : space-between;
        margin          : auto;
        padding         : 10px;
        width           : 85%;

        &.smaller {
            width : 95%;
        }

        &.normal {
            width : 85%;
        }
    }

    &-wrapper-numbers {
        align-items     : center;
        display         : flex;
        justify-content : center;
        width           : 100%;

        button {
            margin-right : 20px;

            &:last-of-type {
                margin-right : 0;
            }
        }
    }

    &-button {
        background    : #FFF;
        border-radius : 50%;
        border        : 1px solid transparent;
        box-shadow    : 0 0 6px #00000042;
        font-size     : 22px;
        font-weight   : bold;
        height        : 60px;
        outline       : none !important;
        transition    : all .4s;
        width         : 60px;

        &:hover {
            background : #f6f6f6;
        }
    }

    &-toggle-button {
        background  : #FFF;
        border      : 1px solid #e0e0e0;
        font-size   : 22px;
        font-weight : bold;
        height      : 50px;
        outline     : none !important;
        transition  : all .4s;
        width       : auto;

        &:hover {
            background : #f6f6f6;
        }

        img {
            height : 80%;
        }

        &.active {
            background    : #e0e0e0;
            border-bottom : 1px solid #2d2d2d;
            border-left   : 1px solid #2d2d2d;
            border-right  : 1px solid #2d2d2d;
            border-top    : 1px solid transparent;
        }
    }
}

.dial-button {
    &.active {
        border-top : 1px solid #2d2d2d !important;
    }
}