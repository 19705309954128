.card {
  border-radius: 0;

  &-search {
    .card-header {
      background-color: $brand-primary;
      color: #FFF;
    }
    .card-footer {
      background-color: #f6f6f6;
      color: #000;
    }
  }

  &-list {
    .card-body {
      padding: 0 1rem;
    }

    .card-header,
    .card-footer {
      background-color: #f6f6f6;
      color: #000;
    }
  }

  &.atbl {
    .card-body {
      padding: 0 1rem;
    }
  }

  &.atbl-table {
    .card-body {
      padding: 0 1rem;

      @media(min-width: 1200px){
        padding: 0;
      }
    }
  }

  &-header {
    &-atbl {
      background-color: #f6f6f6;
    }

    .btn {
      margin: 0;
    }

    &-operations {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-footer {
    &-atbl {
      background-color: #f6f6f6;
    }
  }
}