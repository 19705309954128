.atbl-fake-input {
    @extend .form-control;
    background-color: #d8dbe0;
    border: none;
    box-shadow: none;
    color: #768192;
    font-weight: normal;
    overflow: hidden;
    padding: 6px 12px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &-group {
        margin-bottom: 0;
    }

    &-copy {
		text-decoration: none;
        align-items: center;
        background-color: #d8dbe0;
        color: #768192;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        height: 20px;
        justify-content: center;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .3s ease-in-out;
        width: 20px;

		&:hover {
            color: black;
            opacity: .7;
            text-decoration: none;
        }
    }
}