&-oppwa-client {
    /* The Modal (background) */
    .modal {
        display          : block; /* Hidden by default */
        position         : fixed; /* Stay in place */
        z-index          : 1; /* Sit on top */
        padding-top      : 100px; /* Location of the box */
        left             : 0;
        top              : 0;
        width            : 100%; /* Full width */
        height           : 100%; /* Full height */
        overflow         : auto; /* Enable scroll if needed */
        background-color : rgb(0, 0, 0); /* Fallback color */
        background-color : rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
        background-color : #fefefe;
        margin           : auto;
        border           : 1px solid #888;
    }

    /* Modal Body */
    .modal-body {
        padding : 0;
    }

    /* The Close Button */
    .close {
        color       : #FFF;
        float       : right;
        font-size   : 28px;
        font-weight : bold;
        text-align  : right;
    }

    .close:hover,
    .close:focus {
        color           : #000;
        text-decoration : none;
        cursor          : pointer;
    }

    .spiner {
        text-align : center;

        img {
            width  : 100px;
            height : 100px;
            margin : auto;
        }
    }

    .wpwl-form {
        margin    : 0;
        max-width : unset;
    }

    .wpwl-button-pay {
        color            : #fff;
        background-color : #2eb85c !important;
        border-color     : #2eb85c !important;
    }

    .wpwl-button-pay:hover,
    .wpwl-button-pay:active {
        color            : #fff;
        background-color : #26994d !important;
        border-color     : #248f48;
    }

    .wpwl-button-cancel-form {
        color            : #4f5d73;
        background-color : #ebedef !important;
        border-color     : #ebedef !important;

        &:hover,
        &:focus {
            color            : #4f5d73;
            background-color : #d6dade !important;
            border-color     : #cfd4d8 !important;
        }
    }

    .wpwl-control {
        height : 50px;
    }

    .wpwl-control {
        border : 1px solid #e6e9ec !important;
    }

    .customLabel {
        display     : inline-block;
        width       : 157px;
        padding-top : 10px;
    }

    .customInput {
        display : inline-block;
        width   : 50px;
    }

    .wpwl-label-brand {
        margin-top : 10px;
    }

    .wpwl-group-brand {
        margin-bottom : 30px;
    }

    .wpwl-brand-card {
        margin-top : 15px;
    }

    .wpwl-group {
        margin-top : 5px;
        padding    : 0 15px;

        &:last-of-type {
            margin-bottom : 0;
        }
    }

    input[type="checkbox"] {
        height : 15px !important;
    }

    .wpwl-registration {
        width : 100%
    }

    .wpwl-group {
        display : flex;

        .wpwl-wrapper-submit {
            width          : 75%;
            display        : flex;
            height         : auto;
            padding        : 15px 0;
            margin         : auto;
            flex-direction : row-reverse;

            button {
                width : 50%;

                &:last-of-type {
                    margin-right : 15px;
                }
            }
        }
    }

    .wpwl-wrapper-registration-details {
        padding-left : 15px;
    }

    .wpwl-group-registration.wpwl-selected {
        border-bottom : 1px solid #ccc;
        border-radius : 0;
    }

    .wpwl-wrapper-registration-cvv {
        display : none;
    }

    @media (min-width : 480px) {
        .wpwl-wrapper-registration-details {
            width         : 74.333333%;
            padding-right : 0;
        }
    }

    .wpwl-label-brand,
    .wpwl-control-brand {
        font-weight : bold;
        font-size   : 16px;
    }

    .wpwl-group-registration.wpwl-selected {
        border        : none;
        border-bottom : 1px solid #ccc;
    }

    .wpwl-wrapper-registration.wpwl-wrapper-registration-registrationId {
        input[type="radio"] {
            height : 20px !important;
        }
    }

    #check-box-save {
        padding       : 0 15px;
        margin-bottom : 12px;
    }
}