$switch-colors: map-merge(
	$theme-colors,
	(
		"default": #2ecc71,
		"primary": $brand-primary,
	)
);

@mixin inactiveMixin {
	content: "";
	position: absolute;
	display: block;
}

@mixin beforeAnimation {
	-moz-transition: .2s cubic-bezier(.24, 0, .5, 1);
	-o-transition: .2s cubic-bezier(.24, 0, .5, 1);
	-webkit-transition: .2s cubic-bezier(.24, 0, .5, 1);
	transition: .2s cubic-bezier(.24, 0, .5, 1);
}

@mixin afterAnimation {
	box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05);
	-moz-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
	-o-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
	-webkit-transition: .35s cubic-bezier(.54, 1.60, .5, 1);
	transition: .35s cubic-bezier(.54, 1.60, .5, 1);
}

.atbl-switch {
	&.small {
		label {
			height: 20px;
			width: 40px;

			&:before {
				background: whitesmoke;
				height: 20px;
				width: 40px;
			}

			&:after {
				height: 20px;
				left: 0;
				top: -1px;
				width: 20px;
			}

			.loader {
				border-bottom: 0.2em solid rgba(0, 0, 0, 0.2);
				border-left: 0.2em solid #000;
				border-right: 0.2em solid rgba(0, 0, 0, 0.2);
				border-top: 0.2em solid rgba(0, 0, 0, 0.2);
				height: 15px;
				left: 2px;
				top: 2px;
				width: 15px;
			}

			&.checked {
				&:after {
					left: 20px;
				}

				.loader {
					left: 23px;
				}
			}
		}
	}

	&-toggle {
		opacity: 0;
		position: absolute;
	}

	label {
		-moz-transition: 0.4s ease;
		-o-transition: 0.4s ease;
		-webkit-tap-highlight-color: transparent;
		-webkit-transition: 0.4s ease;
		border-radius: 60px;
		border: 1px solid #e4e4e4;
		display: inline-block;
		height: 30px;
		margin: 0;
		position: relative;
		transition: 0.4s ease;
		user-select: none;
		width: 50px;

		&:before {
			@include inactiveMixin();
			@include beforeAnimation();
			border-radius: 30px;
			height: 30px;
			left: 0;
			top: 0;
			width: 50px;
		}

		&:after {
			@include inactiveMixin();
			@include afterAnimation();
			background: whitesmoke;
			border-radius: 60px;
			cursor: pointer;
			height: 28px;
			left: 0;
			top: 0;
			width: 28px;
		}

		.loader {
			position: absolute;
			left: 4px;
			z-index: 999;
			top: 5px;
		}

		&.checked {
			&:before {
				-moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
				-o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
				-webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
				transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
			}

			@each $color, $value in $switch-colors {
				&.#{$color}:before {
					background: $value;
				}
			}

			&:after {
				left: 54px - 30px;
			}

			.loader {
				left: 28px;
			}
		}
	}

	small {
		margin-left: 1rem;
	}
}