.player-manage-wallet {
  border: none;

  > .card-body {
    padding: 0;
  }

  .tab-content {
    border: none;

    .tab-pane {
      border-top: 1px solid #20a8d8;
    }
  }

  .nav {
    &-tabs {
      margin-top: unset;

      &.card-header-tabs {
        outline: none;
      }
    }

    &-link {
      border: 1px solid transparent !important;
      border-bottom: none;

      &.active,
      &:hover,
      &:focus {
        background-color: #20a8d8 !important;
        border-color: #20a8d8 !important;
        color: #FFF !important;
        outline: none;
      }
    }
  }
}

.card-header .nav.nav-tabs .nav-link.active, .card-header .nav.nav-tabs .navbar .active.dropdown-toggle, .navbar .card-header .nav.nav-tabs .active.dropdown-toggle {
  color: #151b1e;
  background: #fff;
  outline: none;
  border-top: 1px solid #a4b7c1;
}