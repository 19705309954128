.campaigns-list-section {
	.tags-column {
		align-items: center;
		border-top: 1px solid #e0e0e0;
		display: flex;
		flex-wrap: wrap;
		margin-top: 5px;
		padding: 5px;

		span {
			margin-right: 5px;
		}
	}
}