.checkout-summary {
    .avatar {
        width: 40px;
        height: 40px;
    }

    .card-header-operations {
        button {
            padding: 0;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            margin-right: 5px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .items-indicator {
        margin-right : 11px;
        position     : relative;

        &:after {
            content          : "";
            width            : 1px;
            height           : 100%;
            background-color : #ffffff8c;
            position         : absolute;
            right            : -6px;
        }
    }

    &-place-order {
        display : flex;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
    }

    &-total {
        span {
            font-weight: bold;
            color: #666;
        }
    }

    &-item {
        display : flex;
        align-items: center;
        margin-bottom: 10px;
        padding: 10px;
        position: relative;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:nth-child(odd) {
            background-color: #f6f6f6;
        }
    }

    &-image {
        margin-right: 10px;
    }

    &-data {
        flex: 1;
    }

    &-title {
        flex: 1;
        padding-right: 25px;

        .title {
            color: #666;
        }
    }

    &-price {
        display : flex;
        justify-content : space-between;
        align-items: center;
    }

    &-items-data {
        display : flex;
        justify-content : flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    &-operations {
        width: 50px;
        position: absolute;
        top: 5px;
        right: 0;
    }

    &-free-items {
        padding: 0;
        margin: 5px;
        border: 1px solid #e0e0e0;

        legend {
            width: auto;
            text-align: center;
            font-weight: bold;
            text-transform: uppercase;
            padding: 5px 15px;
            margin-bottom: 0;
            font-size: 16px;
            color: #666;
        }
    }
}