.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 2.5em;
  height: 2.5em;

  &-text {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
}