.players {
    #bulkAction {
        .dropdown-item {
            background-color : #fff !important;
            border           : 1px solid #f86c6b;
            color            : #f86c6b;

            &:hover {
                background-color : #f6f6f6 !important;
            }
        }
    }

    &-list {
        .check-box-input {
            vertical-align : middle !important;
        }

        &-mobile {
            display : block;

            @media(min-width : 1200px) {
                display : none;
            }

            .general-list {
                > div {

                    &:first-of-type {
                        display : none;

                        @media(min-width : 1200px) {
                            display : flex;
                        }
                    }
                }

                .dropdown-toggle {
                    padding : 0;
                }
            }
        }

        &-desktop {
            display : none;

            @media(min-width : 1200px) {
                display : block;
            }

            table {
                thead {
                    background-color : #f6f6f6;
                    border-bottom    : 1px solid #a4b7c1;
                    border-top       : 1px solid #a4b7c1;
                    display          : none;
                    font-weight      : bold;

                    @include media-breakpoint-up(lg) {
                        display : table-header-group;
                    }
                }

                tbody {
                    tr {
                        &:nth-child(odd),
                        &.last,
                        &:hover {
                            background-color : #f6f6f6;
                        }

                        .dropdown-toggle {
                            padding : 0;
                        }
                    }

                    td {
                        vertical-align : middle;
                    }
                }
            }
        }
    }
}