.atbl-balance-selector {
    position : relative;

    &-close {
        background-color : #ffffff;
        border-radius    : 50%;
        color            : #e55353;
        display          : flex;
        font-size        : 12px;
        font-weight      : bold;
        height           : 14px;
        position         : absolute;
        right            : 0;
        text-transform   : uppercase;
        top              : 1px;
    }
}