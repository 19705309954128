.sidebar-inner-component {
  position: fixed;
  right: -320px;
  width: 320px;
  height: calc(100vh - 55px);
  background-color: #fff;
  top: 55px;
  transition: .8s;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.small {
    width: 250px;
    right: -250px;
  }

  &.medium {
    right: -100%;
    width: 100%;

    @include media-breakpoint-up(lg){
      right: -500px;
      width: 500px;
    }
  }

  &.large {
    right: -100%;
    width: 100%;

    @include media-breakpoint-up(lg){
      right: -800px;
      width: 800px;
    }
  }

  &.desktop {
    right: -100%;
    width: 100%;

    @include media-breakpoint-up(lg){
      right: -100%;
      width: calc(100% - 256px);
    }
  }

  &.active {
    right: 0px;
  }

  &-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
    background-color: #3097d1;
    color: #FFF;
    font-weight: bold;
    border-bottom: 1px solid #116c8e;

    .close-sidebar {
      background-color: transparent;
      border: none;
      outline: none;
      color: #FFF;
    }
  }

  &-body {
    height: 100%;
    overflow-y: auto;
    padding: 15px;
  }

  &.left {
    &.leadsAddRemoveModal {
      left: -100%;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 36.6666%;
      }
    }

    &.medium {
      left: -100%;
      width: 100%;

      @include media-breakpoint-up(lg){
        left: -550px;
        width: 550px;
      }
    }

    &.active {
      left: 0;

      @include media-breakpoint-up(lg){
        left: 256px;
      }
    }
  }

  &-footer {
    display: flex;
    padding: 15px;
    background-color: #f6f6f6;
    align-items: center;
    justify-content: flex-end;
    box-shadow: -2px 0 6px #00000030;
    z-index: 999;
  }
}