.player-profile-info {
	background-color: #f6f6f6;
	border: 1px solid #d8dbe0;
	margin-bottom: 1rem;
	padding: 0 .615rem;

	&-header {
		align-items: center;
		border-bottom: 1px solid #d8dbe0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: .315rem 0;

		> div {
			width: 100%;

			&:first-of-type {
				text-align: center;

				@include media-breakpoint-up(md) {
					text-align: left;
				}
			}

			@include media-breakpoint-up(md) {
				width: auto;
			}
		}

		a {
			margin-right: .25rem;
		}

		.badge {
			margin-left: .25rem;
		}

		&-info {
			font-size: .75rem;

			span a {
				color: #8f8f8f;
				font-weight: normal;
			}
		}
	}

	&-local-time {
		text-align: center;

		@include media-breakpoint-up(md) {
			text-align: right;
		}

		a {
			margin: 0;
		}

		div {
			span {
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		small {
			color: #9d9d9d;
			line-height: 21px;
		}
	}

	&-body {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: .615rem 0;
	}

	&-info {
		font-size: .75rem;
		text-align: center;
		width: 100%;

		@include media-breakpoint-up(md) {
			text-align: left;
			width: auto;
		}

		div:first-of-type > span {
			margin-right: .25rem;
		}
	}

	&-newsletter {
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(md) {
			justify-content: flex-start;
		}

		.atbl-switch {
			margin-left: .5rem;
		}
	}

	&-operations {
		margin-top: 1rem;
		text-align: center;
		width: 100%;

		@include media-breakpoint-up(md) {
			margin-top: 0;
			text-align: right;
			width: auto;
		}

		.basket {
			button {
				margin-left: auto;
				margin-right: auto;

				@include media-breakpoint-up(md) {
					margin-left: auto;
					margin-right: 0;
				}
			}
		}
	}
}