.free-games {
    &-content {
        align-items     : center;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : space-between;
    }

    .game {
        align-items     : center;
        box-shadow      : 0 0 6px rgba(0, 0, 0, 0.3);
        display         : flex;
        flex-wrap       : wrap;
        justify-content : center;
        position        : relative;
        width           : 100%;

        @include media-breakpoint-up(lg) {
            margin-bottom : 10px;
            width         : calc(50% - 5px);
        }

        .img-holder,
        &-play {
            margin-top : 5px;
            text-align : center;
            width      : 100%;
        }

        p {
            font-size : 16px;
        }

        &-play-info {
            color         : #666;
            font-size     : 12px;
            margin-bottom : 5px;
            margin-right  : 5px;
        }

        &-thumb {
            height : 35px;
            width  : 35px;
        }

        button {
            margin-bottom : 5px;
            padding       : 5px 10px;
        }
    }
}