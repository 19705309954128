.form-control {
  &:focus {
    color: #768192;
    background-color: #fff;
    border-color: $brand-primary;
    outline: 0;
    box-shadow: none;
  }
}

.invalid-feedback {
  font-weight: bold;
}

.disabled-state {
  pointer-events: none;

  * {
    background-color: #d8dbe0 !important;
  }
}

.disabled {
  pointer-events: none;
  opacity: .7;
}