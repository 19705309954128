.ticket-results {
  .draw-results {
    .card-subtitle {
      border-bottom: 1px solid #a4b7c1;
      font-size: 14px;
      color: #a4b7c1 !important;
      padding: 5px 0;
    }

    &-loader-holder {
      height: calc(100% - 27px);
      display: flex;
    }
  }

  .ticket-lines-card {
    &-body {
      padding: 0 15px;
    }
  }

  .numbers-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: flex-start;
    }

    &-wrapper {
      display: flex;
      margin-right: .8rem;
    }
  }

  .draw-date {
    text-align: center;
    margin-top: 15px;

    @include media-breakpoint-up(lg) {
      text-align: left;
      margin-top: 0;
    }
  }

  .main-numbers {
    .main {
      &.circle-num:not(:last-of-type) {
        margin-right: 5px;
      }
    }
  }

  .raffle-numbers {
    flex-wrap: wrap;
    margin: 5px 0;
  }

  .circle-num {
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    margin: 5px 5px 5px 0;
    justify-content: center;
    box-shadow: inset -3px -3px 8px rgba(0,0,0,.2), inset 0 0 16px rgba(0,0,0,.1);
  }

  .match {
    border-color: transparent !important;
    background-color: #20a8d8 !important;
    color: #fff !important;
    box-shadow: inset -3px -3px 8px rgba(0, 0, 0, 0.5), inset 0 0 16px rgba(0, 0, 0, 0.1);

    &.raffle,
    .super6,
    .spiel77,
    .encore {
      box-shadow: none;
      border: 1px solid #000 !important;
    }
  }

  .f-purple {
    border-color: #c61197;
    background-color: #c61197;
    color: #fff;
  }

  .b-purple {
    border-color: #c61197;
  }

  .f-green {
    border-color: #31a846;
    background-color: #31a846;
    color: #fff;
  }

  .b-green {
    border-color: #31a846;
  }

  .f-red {
    border-color: #ce2033;
    background-color: #ce2033;
    color: #fff;
  }

  .b-red {
    border-color: #ce2033;
  }

  .set-for-life-main {
    border-color: #0066ad;
    background-color: #0066ad;
    color: #fff;
  }

  .set-for-life-life {
    border-color: #006f76;
    background-color: #006f76;
    color: #fff;
  }

  .f-blue {
    border-color: #29c6f4;
    background-color: #29c6f4;
    color: #fff;
  }

  .b-blue {
    border-color: #29c6f4;
  }

  .b-yellow {
    border-color: #f9d73d;
  }

  .f-yellow {
    border-color: #f9d73d;
    background-color: #f9d73d;
    color: #000;
  }

  .b-yellow-darker {
    border-color: #f8d401;
  }

  .f-yellow-darker {
    border-color: #f8d401;
    background-color: #f8d401;
    color: #000;
  }

  .b-blue-darker {
    border-color: #1b2d47;
  }

  .f-blue-darker {
    border-color: #1b2d47;
    background-color: #1b2d47;
    color: #FFF;
  }

  .spiel77 {
    border-color: #0089c5;
    background-color: #0089c5;
    color: #FFF;
  }

  .raffle {
    border-color: #ffc107 ;
    background-color: #ffc107 ;
    color: #000;
  }

  .stars {
    border-color: #28b1db ;
    background-color: #28b1db ;
    color: #FFF;
  }

  .supplementary  {
    border-color: #3c6d45 ;
    background-color: #3c6d45 ;
    color: #FFF;
  }

  .super6 {
    border-color: #c30061;
    background-color: #c30061;
    color: #FFF;
  }

  .encore {
    border-color: #1b2d47;
    background-color: #1b2d47;
    color: #FFF;
  }

  .raffle,
  .super6,
  .spiel77,
  .encore {
    padding: 5px;
    min-width: 100px;
    display: inline-block;
    text-align: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}