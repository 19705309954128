.payment-gateways-settings {
	&-item {
		&-order {
			display: flex;
			align-items: center;
			justify-content: space-between;

			input {
				height: 30px;
				font-size: 1rem;
			}

			button {
				align-items: center;
				display: flex;
				height: 30px;
				justify-content: center;
				width: 30px;
				font-size: 1rem;
				margin-left: 10px;
			}
		}
	}
}