.withdrawals-details-modal {
  .modal-footer {
    display: none;
  }

  .wd-dropdown-button .dropdown-toggle {
    display: block;
    width: 100%;
  }

  &-details {
    .row {
      padding: 10px 0;

      &:not(:first-of-type){
        border-top: 1px solid #e0e0e0;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        padding: 18px 0 5px 0;
      }

      .col {
        span {
          &:not(.badge)
          &:not(button) {
            color: #9c9c9c;
            font-weight: bold;
          }
        }

        &:first-of-type {
          text-align: right;
        }
      }
    }
  }
}