.audio-player {
    background-color : #f1f3f4;
    display          : flex;
    flex             : 1;
    margin-top       : 10px;
    position         : relative;

    .vueAudioBetter {
        background-color : #f1f3f4 !important;
        background-image : none !important;
        border-radius    : 0 !important;
        box-shadow       : none !important;
        margin           : 0 !important;
        width            : 100% !important;
        padding          : 10px !important;

        .operate {
            span {
                color : #000 !important;
            }
        }

        .iconfont:active {
            top  : unset !important;
            left : unset !important;
        }
    }

    a {
        align-items      : center;
        background-color : #f1f3f4;
        border           : 1px solid #e0e0e0;
        border-radius    : 50%;
        box-shadow       : 0 0 6px #0003;
        display          : flex;
        height           : 25px;
        justify-content  : center;
        position         : absolute;
        right            : 0;
        text-decoration  : none;
        top              : 0;
        transform        : translate(50%, -50%);
        transition       : .4s;
        width            : 25px;

        &:hover {
            opacity : .7;
        }
    }
}

.audio-link {
    background-color : transparent;
    border           : none;
    color            : #3097d1;
    font-size        : 22px;
    outline          : none !important;
    transition       : .4s;

    &:hover,
    &:active {
        opacity : .7;
    }
}