fieldset {
    border        : 1px solid #e0e0e0;
    margin-bottom : 1rem;
    padding       : 15px;
}

legend {
    color          : #5c5c5c;
    display        : block;
    font-size      : .8rem;
    font-weight    : 500;
    line-height    : inherit;
    max-width      : unset;
    padding        : 5px 10px;
    text-transform : uppercase;
    white-space    : normal;
    width          : auto;
}