.player {
	&-tags-container {
		display: flex;
		flex-wrap: wrap;

		.dropdown {
			display: flex;

			&-toggle {
				align-items: center;
				background-color: $brand-success;
				border-radius: 5px;
				border: 1px solid transparent;
				color: white !important;
				display: flex;
				font-size: 11px;
				height: 20px;
				outline: none;
				padding: 0 5px;
				text-decoration: none;

				&:hover,
				&:focus {
					background-color: white !important;
					border: 1px solid #e0e0e0;
					color: black !important;
				}

				i {
					height: 0.675rem;
				}
			}

			&-menu {
				padding: 0;

				a {
					&.disabled {
						background-color: #f1f1f1;
					}
				}
			}
		}
	}

	&-tag {
		align-items: center;
		background-color: $brand-success;
		border-radius: 5px;
		color: white;
		display: flex;
		font-size: 12px;
		height: 20px;
		margin-bottom: 5px;
		margin-right: 10px;
		padding: 0 5px;

		&.inactive {
			background-color: lightgrey;
			color: black;
		}

		&.primary {
			background-color: $brand-primary;
			color: white;
		}

		a {
			border-radius: 5px;
			border: 1px solid transparent;
			color: white;
			margin-left: 10px;
			margin-right: -5px;
			padding: 0 5px 0;
			transition: .4s;

			&:hover,
			&:focus {
				background-color: white;
				border: 1px solid #e0e0e0;
				color: black;
			}
		}
	}

	&-profile-actions-holder {
		font-size: 12px;
		line-height: 16px;
		width: 100%;

		@media (min-width: 992px) {
			width: auto;
		}

		button {
			width: 100%;
			margin-bottom: 5px;

			@media (min-width: 992px) {
				width: auto;
				margin-bottom: 0;
			}
		}
	}
}