.agents {
  &-list {
    &-first-row {
      border-bottom: 1px solid #e0e0e0;
      font-weight: bold;
    }

    &-total {
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      background-color: #f6f6f6;
      margin-bottom: 20px;
      font-weight: bold;

      .col {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}