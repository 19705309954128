.atbl-checkbox {
    align-items           : center;
    display               : flex;
    font-family           : system-ui, sans-serif;
    font-weight           : bold;
    grid-template-columns : 1em auto;
    line-height           : 1.1;
    margin                : 0;

    &.disabled {
        opacity        : .7;
        pointer-events : none;
    }

    &.partial-check {
        input[type="checkbox"] {
            &::before {
                background-color : currentColor;
                clip-path        : unset;
                content          : "";
                height           : 3px;
                left             : 50%;
                position         : absolute;
                top              : 50%;
                transform        : scale(1) translate(-50%, -50%);
                transform-origin : bottom left;
                width            : 90%;
            }
        }
    }

    span {
        color     : #202124ab;
        font-size : 12px;
    }

    input[type="checkbox"] {
        -webkit-appearance : none;
        appearance         : none;
        background-color   : white;
        border             : 0.15em solid currentColor;
        border-radius      : 0.15em;
        color              : currentColor;
        display            : grid;
        font               : inherit;
        height             : 20px !important;
        margin             : 0 5px 0 0;
        outline            : none;
        place-content      : center;
        position           : relative;
        transform          : translateY(-0.075em);
        width              : 20px !important;

        &::before {
            background-color : white;
            box-shadow       : inset 1em 1em currentColor;
            clip-path        : polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            content          : "";
            height           : 0.8em;
            transform        : scale(0);
            transform-origin : bottom left;
            width            : 0.9em;
        }

        &:checked::before {
            transform : scale(1);
        }

        &:disabled {
            color  : #e0e0e0;
            cursor : not-allowed;
        }
    }
}