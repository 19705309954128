.commission-eligible-transactions-single-day {
	.details-holder {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		margin-bottom: 1rem;

		@include media-breakpoint-up(md) {
			flex-wrap: unset;
		}

		> div {
			&:first-of-type {
				flex-basis: 100%;

				@include media-breakpoint-up(md) {
					flex-basis: 40%;
				}
			}

			&:last-of-type {
				flex-basis: 100%;

				@include media-breakpoint-up(md) {
					flex-basis: 60%;
				}
			}
		}

		.card {
			margin-bottom: 0;
		}
	}
}