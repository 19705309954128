$sidebar-color-hover-active: rgba(48, 151, 209, 0.7);

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: $white;
  background: $sidebar-color-hover-active !important;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
   color: $white;
   background: $sidebar-color-hover-active !important;
 }

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  color: $white;
  background: $sidebar-color-hover-active !important;
}

.c-sidebar {
  &-nav-dropdown {
    color: $white;

    &.c-show {
      background: rgba(2, 166, 242, 0.15) !important;

      .c-sidebar-nav-dropdown-toggle {
        border-bottom: 1px solid #2b2b2b;
      }
    }
  }
}