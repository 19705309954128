.lottery-payment {
    &-types {
        display : flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px 0 0 10px;

        button {
            width: calc(16.6666% - 10px);
            margin: 0 10px 10px 0;
        }
    }

    &-card {
        margin-top: 0.1rem !important;
        margin-bottom: 0.1rem !important;
    }

    &-saved-cards {
        margin-top: 0.1rem !important;
        margin-bottom: 0.1rem !important;
    }

    &-balance {
        margin-top: 0.1rem !important;
        margin-bottom: 0.1rem !important;
    }
}