.status-content {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;

  &.full {
    min-height: 100vh;
  }

  &-logo,
  &-header,
  &-body {
    display: flex;
    width: 100%;
    justify-content: center;

    &:not(:last-of-type){
      margin-bottom: 30px;
    }
  }

  &-logo {
    img {
      max-width: 200px;
    }
  }

  &-header {
    font-size: 180px;
    font-weight: bold;
    line-height: 180px;
    letter-spacing: 10px;
    color: #2d2d2d;
  }

  &-body {
    font-size: 16px;
    line-height: 18px;
  }
}