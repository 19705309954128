.loader {
    font-size: 10px;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    margin: auto;

    &.left {
        margin-left: 0px;
    }

    &.right {
        margin-right: 0px;
    }

    &.big {
        border-radius: 50%;
        width: 10em;
        height: 10em;
        border-top: 1.1em solid rgba(0, 0, 0, 0.2);
        border-right: 1.1em solid rgba(0, 0, 0, 0.2);
        border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
        border-left: 1.1em solid #000;
    }

    &.normal {
        border-radius: 50%;
        width: 5em;
        height: 5em;
        border-top: .8em solid rgba(0, 0, 0, 0.2);
        border-right: .8em solid rgba(0, 0, 0, 0.2);
        border-bottom: .8em solid rgba(0, 0, 0, 0.2);
        border-left: .8em solid #000;
    }

    &.small {
        border-radius: 50%;
        width: 3em;
        height: 3em;
        border-top: .5em solid rgba(0, 0, 0, 0.2);
        border-right: .5em solid rgba(0, 0, 0, 0.2);
        border-bottom: .5em solid rgba(0, 0, 0, 0.2);
        border-left: .5em solid #000;
        margin: 10px auto;
    }

    &.smaller {
        border-radius: 50%;
        width: 2em;
        height: 2em;
        border-top: .3em solid rgba(0, 0, 0, 0.2);
        border-right: .3em solid rgba(0, 0, 0, 0.2);
        border-bottom: .3em solid rgba(0, 0, 0, 0.2);
        border-left: .3em solid #000;
    }

    &.white {
        border-top: .3em solid rgba(255, 255, 255, 0.2);
        border-right: .3em solid rgba(255, 255, 255, 0.2);
        border-bottom: .3em solid rgba(255, 255, 255, 0.2);
        border-left: .3em solid #FFF;
    }
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}