.form-group-atbl {
  @extend .form-group;
  flex: 1;
  font-weight: bold;

  &-error {
    font-size: 12px;
    font-weight: bold;
    color: $danger;
  }
}