@import "call-history";
@import "leads";
@import "campaigns-call";
@import "campaign-edit";
@import "campaign-list";

.filter-links-holder {
    display                    : flex;
    overflow-x                 : auto;
    -webkit-overflow-scrolling : touch;
    -ms-overflow-style         : -ms-autohiding-scrollbar;
    padding                    : 10px 0;
}

.filter-link {
    flex : 0 0 auto;
}

.checkbox-leads {
    height : 18px;
    width  : 18px;
}

.in-campaign-checkbox {
    height : 20px;
    width  : 20px;
}

.status-column {
    @media (min-width : 1200px) {
        flex : 0 0 80px;
    }
}

.description-column {
    white-space   : nowrap;
    overflow      : hidden;
    text-overflow : ellipsis;
}