.order-preview {
    &-tabs {
        .nav {
            background-color: #f6f6f6;
            padding: 15px 15px 0 15px !important;
        }

        .tab-content {
            padding: 0 15px !important;
        }
    }

    .order-info-wrapper {
        display: flex;
        margin: auto;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            width: 80%;
        }

        &-holder {
            width: 100%;
            text-align: center;
            display : flex;
            justify-content : center;
            flex-wrap: wrap;
            align-items: center;

            @include media-breakpoint-up(lg) {
                width: calc(33.33% - 5px);

                &.half {
                    width: calc(16.5% - 5px);
                }
            }

            &.hide-mobile {
                display: none;

                @include media-breakpoint-up(xl) {
                    display : flex;
                }
            }
        }
    }
}