.app-body {
  &.presenter {
    margin: 0;
    background-color: #FFF;
    font-size: 100%;

    @media(min-width: 992px){
      font-size: 130%;
    }

    main {
      margin: 0;
    }
  }
}