.btn {
  border-radius: 0;

  &-link {
    font-weight: 400;
    color: $brand-primary !important;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: $brand-primary-light !important;
      color: $white !important;
      box-shadow: none !important;
    }
  }

  .btn-link-dark {
    font-weight: 400;
    color: $primary;
    text-decoration: none;
  }

  &-primary {
    background-color: $brand-primary !important;
    border-color: $brand-primary !important;
    color: $white;

    &:hover,
    &:focus {
      background-color: $brand-primary-light !important;
      color: $white;
      box-shadow: none !important;
    }
  }

  &-orange {
    background-color: $brand-orange !important;
    border-color: $brand-orange !important;
    color: $white;

    &:hover,
    &:focus {
      background-color: $brand-orange-light !important;
      color: $white;
      box-shadow: none !important;
    }
  }

  &-primary-dark {
    background-color: $primary;
    border-color: $primary;
    color: $white;

    &:hover,
    &:focus {
      background-color: $primary-50;
      color: $white;
    }
  }

  &-outline-primary {
    color: $brand-primary;
    border-color: $brand-primary !important;

    &:hover,
    &:focus {
      color: $white !important;
      background-color: $brand-primary !important;
      border-color: $brand-primary !important;
    }
  }

  &-warning {
    color: $black;
  }

  &-delete {
    padding: 0;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    text-decoration: none !important;
  }

  &-confirm {
    padding: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    text-decoration: none !important;

    &-action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}