$dropdown-colors: (
		"filled": $theme-colors,
		"outline": (
				"outline-primary":    $primary,
				"outline-secondary":  $secondary,
				"outline-success":    $success,
				"outline-info":       $info,
				"outline-warning":    $warning,
				"outline-danger":     $danger,
				"outline-light":      $light,
				"outline-dark":       $dark
		),
);

$ddColors: join(
				map-keys($theme-colors),
	[
		"outline-primary"
		"outline-secondary",
		"outline-success",
		"outline-info",
		"outline-warning",
		"outline-danger",
		"outline-light",
		"outline-dark"
	]
);

.atbl-dropdown {
	position: relative;

	&.disabled {
		opacity: .7;
		pointer-events: none;
	}

	&.sm {
		.atbl-dropdown {
			&-header {
				@extend .btn-sm;
			}

			&-item {
				padding: .5rem 1.25rem;
			}
		}
	}

	&-header {
		background-color: transparent;
		border: none;
		cursor: pointer;
		display: inline-block;
		font-size: 14px;
		outline: none;
		padding: .75rem;
		width: 100%;

		span {
			margin-right: .25rem;
			font-size: 14px;
		}

		&.input {
			align-items: center;
			background-color: #fff;
			border: 1px solid #d8dbe0;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
		}

		@each $key, $value in $ddColors {
			&.#{$key} {
				@extend .btn;
				@extend .btn-#{$key};
			}
		}

		&:focus {
			outline: none;
		}
	}

	&-item {
		cursor: pointer;
		display: flex;
		width: 100%;
		color: #3c4b64;
		font-size: 14px;
		padding: .75rem;
		text-decoration: none;

		&.disabled {
			cursor: not-allowed;
			opacity: .7;
			pointer-events: none;
		}

		&:hover {
			background-color: #f6f6f6;
			text-decoration: none;
			color: #3c4b64;
		}
	}

	&-content {
		position: absolute;
		top: 100%;
		z-index: 1000;
		min-width: 200px;
		background-color: #fff;
		border-bottom: 1px solid #d8dbe0;
		border-left: 1px solid #d8dbe0;
		border-radius: 0.25rem;
		border-right: 1px solid #d8dbe0;
		display: none;

		&.left {
			left: 0;
		}

		&.right {
			right: 0;
		}

		&.center {
			left: 50%;
			transform: translateX(-50%);
		}

		&.top-left {
			bottom: 0;
			left: 0;
		}

		&.top-right {
			bottom: 0;
			right: 0;
		}

		&.top-center {
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}

		&.show {
			display: block;
		}
	}

	&-divider {
		border-top: 1px solid #d8dbe0;
		height: 0;
		margin: 0.5rem 0;
		overflow: hidden;
	}
}