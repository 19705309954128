.atbl-placeholder {
	display: block;
	color: #2d2d2d;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: .5px;

	&.transform-none {
		text-transform: none;
	}
}