.permissions-manager {
    .roles-holder {
        align-items     : center;
        border-top      : 1px solid #e0e0e0;
        display         : flex;
        flex-wrap       : wrap;
        justify-content : flex-start;
        margin-bottom   : 5px;
        padding-top     : 15px;

        > div {
            margin-bottom : 5px;
            padding       : 5px;
            width         : 50%;

            @include media-breakpoint-up(sm) {
                width : calc(33.3333% - 10px);
            }
        }
    }
}
