.payment-type-badge {
	display: inline-block;
	padding: 0.25em 0.4em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	-webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

	&.f-purple {
		background-color: #c61197;
		color: #ffffff;
	}

	&.f-pink {
		background-color: #dd57b8;
		color: #ffffff;
	}

	&.f-green {
		background-color: #31a846;
		color: #ffffff;
	}

	&.b-red {
		background-color: #ce2033;
		color: #ffffff;
	}

	&.b-blue {
		background-color: #29c6f4;
		color: #ffffff;
	}

	&.mixed {
		color: #ffffff;
		background-color: #6c757d;
	}

	&.wallet {
		color: #212529;
		background-color: #ffc107;
	}

	&.f-orange {
		color: #ffffff;
		background-color: #ff8707;
	}

	&.f-brown {
		color: #ffffff;
		background-color: #814610;
	}
}