.permissions-holder {
  &-items {
    display: flex;
    flex-wrap: wrap;

    &-item {
      width: 100%;
      border: 1px solid #e4e7ea;
      display: flex;
      align-items: center;
      padding: 5px 10px;

      @include media-breakpoint-up(sm){
        width: 50%;
      }

      @include media-breakpoint-up(lg){
        width: 25%;
      }

      input[type="checkbox"] {
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }

      label {
        margin: 0;
      }
    }
  }
}