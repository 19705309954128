.send-email {
    .ticket-preview {
        &.vertical {
            padding    : 10px 0;
            border-top : 1px solid #e0e0e0;

            .ticket-preview-holder {
                margin-top : 20px;
            }

            .ticket-preview-holder-row {
                flex-direction : column;

                > div {
                    &:first-of-type {
                        margin-bottom : 10px;
                    }

                    &:last-of-type {
                        margin-left : 0;
                        text-align  : center;
                    }
                }
            }
        }

        &-holder {
            &-row {
                display     : flex;
                align-items : center;

                > div {
                    &:first-of-type {
                        padding          : 5px;
                        background-color : #f6f6f6;
                    }

                    &:last-of-type {
                        margin-left : 10px;
                    }
                }
            }
        }
    }

    &-client {
        position   : relative;
        text-align : center
    }

    .magic-link-redirect-form {
        &__custom-link {
            display: flex;

            .form-group-atbl {
                margin-bottom: 0;

                &:first-of-type {
                    flex: .2;
                    margin-right: 1rem;

                    input {
                        text-align: center;

                        &:last-of-type {
                            text-align: left;
                        }
                    }
                }
                &:last-of-type {
                    flex: 1;
                }
            }
        }
    }
}