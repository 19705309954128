.hide-close {
  .modal {
    &-header {
      .close {
        display: none;
      }
    }
  }
}

.hide-footer {
	.modal-footer {
		display: none;
	}
}

.modal {
  &-header {
    background-color: $brand-primary;
    color: #FFF;

    .close {
      color: #FFF;
    }
  }
  &-footer {
    background-color: $light;
  }
}