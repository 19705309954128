.leads-section {
    .filter {
        font-size    : 18px;
        margin-right : 15px;
    }

    .card-list {
        .card-header {
            flex-wrap: wrap;
            justify-content: center !important;

            @include media-breakpoint-up(sm) {
                justify-content: space-between !important;
            }

            span {
                display: block;
                width: 100%;
                text-align: center;
                margin-bottom: 10px;

                @include media-breakpoint-up(sm) {
                    display: inline-block;
                    width: auto;
                    margin-bottom: 0;
                }
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                button {
                    margin-right: 5px;

                    //@include media-breakpoint-up(lg) {
                    //    margin-right: 0;
                    //}
                }

                span {
                    margin-top: 10px;
                    margin-bottom: 0;

                    @include media-breakpoint-up(sm) {
                        margin-top: 0;
                    }

                    button {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &-list {
        &-row {
            border-bottom : 1px solid #e0e0e0;
            padding       : 10px 0;

            &:nth-child(1) {
                background-color : #f6f6f6;
                border           : 1px solid #a4b7c1;
                font-weight      : bold;
                margin-bottom    : 1rem;
            }

            &:nth-child(odd) {
                background-color : #f6f6f6;
            }

            &:hover {
                background-color : #f6f6f6;
            }

            &.disabled {
                opacity : .3;
            }
        }
    }

    &-profile {
        div {
            margin-bottom : 10px;
        }

        &-label {
            color          : #666;
            display        : block;
            font-size      : 12px;
            font-weight    : 400;
            letter-spacing : .5px;
            text-transform : uppercase;

            @media(min-width : 992px) {
                color : #2d2d2d;
            }
        }

        &-text {
            background-color : #f6f6f6;
            border           : none;
            box-shadow       : none;
            padding          : 6px 12px;
            width            : 100%;
        }
    }

    .import-leads-errors-log-list {
        max-height : 500px;
        overflow-x : hidden;
        overflow-y : auto;

        .row {
            padding : 0.5rem;

            div {
                pointer-events : none;
            }
        }
    }
}