.email-templates {
    &-meta-group {
        border-bottom : 1px solid #e0e0e0;
        margin-bottom : 1.25rem;

        &:last-of-type {
            border-bottom : none;
            margin-bottom : 0;
        }
    }

    &-meta-fields {
        background-color : #ecf0f3;
        padding          : 10px;

        &:nth-child(odd) {
            background-color : #fff;
        }
    }

    &-internal-links {
        background-color : #d9e5ef;
    }
}