.flag-select {
  img.option__image {
    width: 30px;
    margin-right: 10px;
  }
}
.add-dispositions-holder {
  form {
    width: 100%;
  }

  &-dropdown {
    flex:1;

    select {
      margin: 0 !important;
    }
  }
}
.player-call-component {
  .dial-buttons {
    display: flex;
    width: 100%;

    .mute-button {
      flex: 0.1 !important;
      border: none;
      width: 50px;
      display: block;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0.1;
      -ms-flex: 0.1;
      outline: none;
      padding: 10px 15px;
      font-size: 16px;
      box-shadow: none;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .btn {
      flex: 1;
      outline: none;
      box-shadow: none;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .keypad-button {
      background-color: #e0e0e0;
      border: none;
      width: 50px;
      display: block;
      align-items: center;
      flex: 0.1;
      outline: none;
      padding: 10px 15px;

      img {
        width: 100%;
        display: block;
      }

      &:focus {
        outline: none;
      }

      &.active {
        background-color: #e0e0e0;
        border-right: 1px solid #2d2d2d;
        border-bottom: 1px solid #2d2d2d;
        border-left: 1px solid #2d2d2d;
      }
    }
  }

  .keypad-icon-holder {
    display: flex;
    width: 100%;

    .keypad-icon {
      background-color: #000;
      width: 2px;
      height: 2px;
      border-radius: 50%;
    }
  }
}